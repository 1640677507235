import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { IOrder, IOrderUpdateInput } from 'aupiq-pos-shared/src/schemas/Order'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useUpdateOrder = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { data: user } = useConnectedUser()

  return useMutation<
    IOrder,
    DefaultError,
    {
      id: string
      order: IOrderUpdateInput
    }
  >({
    mutationFn: async ({ id, order }) => {
      return axios
        .patch(`/api/businesses/${user?.businessId}/orders/${id}`, order)
        .then(res => res.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'orders'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('order.errors.saving', 'while saving the order '),
      })
    },
  })
}

export default useUpdateOrder
