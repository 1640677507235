import { Button, Flex, Text } from '@chakra-ui/react'
import { OrderCancelingReason } from 'aupiq-pos-shared/src/schemas/Order'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onChoose: (cancelaingReason: OrderCancelingReason) => void
}

const ChooseCancelingReasonDialog: FC<Props> = ({ onChoose }) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={4}>
      <Text>
        {t('order.cancel.reason.explainer', 'Select cancellation reason')}
      </Text>
      <Button onClick={() => onChoose(OrderCancelingReason.CurstomerRequest)}>
        {t(
          `order.cancel.reason.${OrderCancelingReason.CurstomerRequest}`,
          OrderCancelingReason.CurstomerRequest,
        )}
      </Button>
      <Button onClick={() => onChoose(OrderCancelingReason.InputError)}>
        {t(
          `order.cancel.reason.${OrderCancelingReason.InputError}`,
          OrderCancelingReason.InputError,
        )}
      </Button>
      <Button onClick={() => onChoose(OrderCancelingReason.DuplicateOrder)}>
        {t(
          `order.cancel.reason.${OrderCancelingReason.DuplicateOrder}`,
          OrderCancelingReason.DuplicateOrder,
        )}
      </Button>
      <Button onClick={() => onChoose(OrderCancelingReason.SystemError)}>
        {t(
          `order.cancel.reason.${OrderCancelingReason.SystemError}`,
          OrderCancelingReason.SystemError,
        )}
      </Button>
    </Flex>
  )
}

export default ChooseCancelingReasonDialog
