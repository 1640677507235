import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import ProductCategoryCard from '../../../../shared/components/ProductCategoryCard'
import ProductCategoryListComposite from '../../../../shared/components/ProductCategoryListComposite'
import ProductListLoading from '../../../../shared/components/ProductListLoading'

interface Props {
  categories: IProductCategory[]
  selectedCategoryId: string | undefined
  onClickCategory: (categoryId: IProductCategory['id']) => void
  onClearSelection: () => void
}

const ProductCategoryList: FC<Props> & { Loading: FC } = ({
  categories,
  selectedCategoryId,
  onClickCategory,
  onClearSelection,
}) => {
  const { t } = useTranslation()

  return (
    <ProductCategoryListComposite>
      <ProductCategoryCard
        category={{
          id: '',
          name: t('product-category.all-products.label', 'All'),
          color: 'gray.600',
        }}
        isActive={!selectedCategoryId}
        onClick={onClearSelection}
      />
      {categories.map(category => (
        <ProductCategoryCard
          key={category.id}
          category={category}
          isActive={category.id === selectedCategoryId}
          onClick={onClickCategory}
        />
      ))}
    </ProductCategoryListComposite>
  )
}

ProductCategoryList.Loading = ProductListLoading

export default ProductCategoryList
