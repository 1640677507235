import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IProduct,
  IProductUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Product'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useUpdateProduct = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: user } = useConnectedUser()

  return useMutation<
    IProduct,
    DefaultError,
    { id: string; product: IProductUpdateInput }
  >({
    mutationFn: async ({ id, product }) => {
      return axios.patch(
        `/api/businesses/${user?.businessId}/products/${id}`,
        product,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'products'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('product.errors.updating', 'while updating the product'),
      })
    },
  })
}

export default useUpdateProduct
