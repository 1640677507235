import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react'
import { IUserLogin, userLoginSchema } from 'aupiq-pos-shared/src/schemas/User'
import { Field, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const attrs: Record<
  keyof IUserLogin,
  { id: keyof IUserLogin; i18nId: string }
> = {
  username: {
    id: 'username',
    i18nId: 'user.attributes.username.label',
  },
  password: {
    id: 'password',
    i18nId: 'user.attributes.password.label',
  },
}

const defaultInitialValues = {
  username: '',
  password: '',
}

interface Props {
  initialValues?: IUserLogin
  onSubmit: (userLogin: IUserLogin) => Promise<void>
}

const LoginForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <Formik<IUserLogin>
      validationSchema={toFormikValidationSchema(userLoginSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isInvalid={Boolean(errors[attrs.username.id])}>
              <FormLabel htmlFor={attrs.username.id}>
                {t(attrs.username.i18nId, 'Username')}
              </FormLabel>
              <Field
                as={Input}
                id={attrs.username.id}
                name={attrs.username.id}
              />
              <FormErrorMessage>{errors[attrs.username.id]}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors[attrs.password.id])}>
              <FormLabel htmlFor={attrs.password.id}>
                {t(attrs.password.i18nId, 'Password')}
              </FormLabel>
              <Field
                as={Input}
                type="password"
                id={attrs.password.id}
                name={attrs.password.id}
              />
              <FormErrorMessage>{errors[attrs.password.id]}</FormErrorMessage>
            </FormControl>

            <Button
              w="40"
              type="submit"
              size="lg"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              {t('common.actions.login', 'Login')}
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
