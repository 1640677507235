import { FC } from 'react'

import ProductCard from './ProductCard'
import ProductListComposite from './ProductListComposite'

const ProductListLoading: FC = () => {
  return (
    <ProductListComposite>
      <ProductCard.Loading />
      <ProductCard.Loading />
      <ProductCard.Loading />
      <ProductCard.Loading />
    </ProductListComposite>
  )
}

export default ProductListLoading
