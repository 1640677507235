import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IBusiness,
  IBusinessUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Business'
import axios from 'axios'

const useUpdateBusiness = () => {
  const queryClient = useQueryClient()

  return useMutation<
    IBusiness,
    DefaultError,
    { id: string; business: IBusinessUpdateInput }
  >({
    mutationFn: async ({ id, business }) => {
      return axios
        .patch(`/api/businesses/${id}`, business)
        .then(res => res.data)
    },
    onSuccess: (_, business) => {
      queryClient.invalidateQueries({ queryKey: ['business', business.id] })
      // TODO: fix hack
      // since language is not instantly updated on UI
      // I just reload the page manually 🤷‍♂️
      location.reload()
    },
  })
}

export default useUpdateBusiness
