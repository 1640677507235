import { useQuery } from '@tanstack/react-query'
import { userWithoutPasswordSchema } from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { z } from 'zod'

const useFetchUsersForBusiness = (businessId: string) => {
  return useQuery({
    queryKey: ['business', businessId, 'users'],
    queryFn: () =>
      axios
        .get(`/api/businesses/${businessId}/users`)
        .then(res => z.array(userWithoutPasswordSchema).parse(res.data)),
  })
}

export default useFetchUsersForBusiness
