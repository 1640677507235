import { FC } from 'react'

import ProductCategoryCard from './ProductCategoryCard'
import ProductCategoryListComposite from './ProductCategoryListComposite'

const ProductCategoryListLoading: FC = () => {
  return (
    <ProductCategoryListComposite>
      <ProductCategoryCard.Loading />
      <ProductCategoryCard.Loading />
      <ProductCategoryCard.Loading />
      <ProductCategoryCard.Loading />
    </ProductCategoryListComposite>
  )
}

export default ProductCategoryListLoading
