import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react'
import {
  IUserCreateRequest,
  UserRole,
  UserUtils,
  userCreateRequestSchema,
} from 'aupiq-pos-shared/src/schemas/User'
import { Field, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const attrs: Record<
  keyof IUserCreateRequest,
  { id: keyof IUserCreateRequest; i18nId: string }
> = {
  username: {
    id: 'username',
    i18nId: 'user.attributes.username.label',
  },
  password: {
    id: 'password',
    i18nId: 'user.attributes.password.label',
  },
  role: {
    id: 'role',
    i18nId: 'user.attributes.role.label',
  },
}

interface Props {
  initialValues: IUserCreateRequest
  onSubmit: (user: IUserCreateRequest) => Promise<void>
  submitButtonText: string
}

const UserForm: FC<Props> = ({ initialValues, onSubmit, submitButtonText }) => {
  const { t } = useTranslation()

  const { data: user } = useConnectedUser()

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <Formik<IUserCreateRequest>
      validationSchema={toFormikValidationSchema(userCreateRequestSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(errors[attrs.username.id])}>
                <FormLabel htmlFor={attrs.username.id}>
                  {t(attrs.username.i18nId, 'Username')}
                </FormLabel>
                <Field
                  as={Input}
                  id={attrs.username.id}
                  name={attrs.username.id}
                />
                <FormErrorMessage>{errors[attrs.username.id]}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors[attrs.password.id])}>
                <FormLabel htmlFor={attrs.password.id}>
                  {t(attrs.password.i18nId, 'Password')}
                </FormLabel>
                <Field
                  as={Input}
                  type="password"
                  id={attrs.password.id}
                  name={attrs.password.id}
                />
                <FormErrorMessage>{errors[attrs.password.id]}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors[attrs.role.id])}>
                <FormLabel htmlFor={attrs.role.id}>
                  {t(attrs.role.i18nId, 'Role')}
                </FormLabel>
                <Field as={Select} id={attrs.role.id} name={attrs.role.id}>
                  {Object.keys(UserRole)
                    // disable creating admins from UI
                    .filter(role => role !== UserRole.Admin)
                    .filter(role =>
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      UserUtils.canCreateUserWithRole(user.role, role as any),
                    )
                    .map(role => (
                      <option key={role} value={role}>
                        {t(`user.role.${role}`, role)}
                      </option>
                    ))}
                </Field>
                <FormErrorMessage>{errors[attrs.role.id]}</FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                width="full"
                colorScheme="blue"
                isLoading={isSubmitting}
              >
                {submitButtonText}
              </Button>
            </VStack>
          </form>
        )
      }}
    </Formik>
  )
}

export default UserForm
