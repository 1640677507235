import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IUserCreateRequest,
  IUserWithoutPassword,
} from 'aupiq-pos-shared/src/schemas/User'
import { compareAsc } from 'date-fns'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ActionMenu from '../../shared/components/ActionMenu'
import Modal from '../../shared/components/Modal'
import UserCard from '../../shared/components/UserCard'
import PageLayout from '../../shared/layouts/PageLayout'
import useConnectedUser from '../../shared/requests/useConnectedUser'
import CreateUserButton from '../components/CreateUserButton'
import CreateUserForm from '../components/CreateUserForm'
import useActivateUser from '../requests/useActivateUser'
import useCreateUser from '../requests/useCreateUser'
import useDeactivateUser from '../requests/useDeactivateUser'
import useDeleteUser from '../requests/useDeleteUser'
import useFetchUsers from '../requests/useFetchUsers'

const ManageUsersPage: FC = () => {
  const { t } = useTranslation()

  const { data: users, isLoading } = useFetchUsers()
  const { data: connectedUser } = useConnectedUser()

  const createUser = useCreateUser()
  const deleteUser = useDeleteUser()
  const activateUser = useActivateUser()
  const deactivateUser = useDeactivateUser()

  const {
    isOpen: isCreateUserModalOpen,
    onOpen: onOpenCreateUserModal,
    onClose: onCloseCreateUserModal,
  } = useDisclosure()

  const cancelDeleteUserButtonRef = useRef(null)

  const [userWithActiveDialog, setUserWithActiveDialog] = useState<null | {
    user: IUserWithoutPassword
    dialog: 'menu' | 'confirm-delete'
  }>(null)

  const openUserMenu = (user: IUserWithoutPassword) => {
    const isConnectedUser = user.id === connectedUser?.id
    // don't show action menu for current user
    if (isConnectedUser) {
      return
    }

    setUserWithActiveDialog({ user, dialog: 'menu' })
  }

  const openUserToDelete = (user: IUserWithoutPassword) => {
    setUserWithActiveDialog({ user, dialog: 'confirm-delete' })
  }

  const closeUserDialogs = () => {
    setUserWithActiveDialog(null)
  }

  const onCreateUser = async (user: IUserCreateRequest) => {
    await createUser.mutateAsync(user)
  }

  if (isLoading) {
    return <div>loading...</div>
  }

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={userWithActiveDialog?.dialog === 'confirm-delete'}
        leastDestructiveRef={cancelDeleteUserButtonRef}
        onClose={closeUserDialogs}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('user.delete.title', 'Delete member')}
              {' - '}
              {userWithActiveDialog?.user.username}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                'common.delete.confirm-message',
                "Are you sure? You can't undo this action afterwards",
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDeleteUserButtonRef}
                onClick={closeUserDialogs}
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (!userWithActiveDialog?.user) {
                    return
                  }
                  try {
                    await deleteUser.mutateAsync(userWithActiveDialog.user.id)
                    closeUserDialogs()
                  } catch (err) {
                    /* empty */
                  }
                }}
                ml={3}
              >
                {t('common.actions.delete', 'Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        title={t('user.create.title', 'Create new user')}
        isOpen={isCreateUserModalOpen}
        onClose={onCloseCreateUserModal}
      >
        <CreateUserForm
          onSubmit={async (user: IUserCreateRequest) => {
            try {
              await onCreateUser(user)
              onCloseCreateUserModal()
            } catch (err) {
              /* empty */
            }
          }}
        />
      </Modal>
      {userWithActiveDialog?.user && (
        <ActionMenu
          isOpen={userWithActiveDialog?.dialog === 'menu'}
          onClose={closeUserDialogs}
          title={userWithActiveDialog?.user.username || ''}
          items={[
            {
              title: userWithActiveDialog.user.isActive
                ? t('user.status.deactivate.action', 'Suspend account')
                : t('user.status.activate.action', 'Activate account'),
              explainer: userWithActiveDialog.user.isActive
                ? t(
                    'user.status.deactivate.explainer',
                    'User will lose access to the system',
                  )
                : t(
                    'user.status.activate.explainer',
                    'User will regain access to the system',
                  ),
              onClick: async () => {
                if (userWithActiveDialog.user.isActive) {
                  await deactivateUser.mutateAsync(userWithActiveDialog.user.id)
                } else {
                  await activateUser.mutateAsync(userWithActiveDialog.user.id)
                }
                closeUserDialogs()
              },
            },
            'divider',
            {
              title: t('common.actions.delete', 'Delete'),
              isDestructive: true,
              onClick: () => openUserToDelete(userWithActiveDialog.user),
            },
          ]}
        />
      )}
      <PageLayout title={t('pages.manage-users', 'Staff')}>
        <Grid templateColumns="repeat(auto-fill, minmax(240px, 1fr))" gap={4}>
          <CreateUserButton onClick={onOpenCreateUserModal} />
          {users
            ?.toSorted((a, b) => compareAsc(a.created_at, b.created_at))
            .map(user => {
              return (
                <GridItem key={user.id}>
                  <UserCard
                    user={user}
                    isCurrentUser={user.id === connectedUser?.id}
                    onClick={() => openUserMenu(user)}
                  />
                </GridItem>
              )
            })}
        </Grid>
      </PageLayout>
    </>
  )
}

export default ManageUsersPage
