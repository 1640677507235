import Digit from './Digit'

const MAX_VALUE = 1_000_000

const numberToDigits = (n: number): Digit[] => {
  n = Math.floor(Math.min(n, MAX_VALUE))
  const digits = []

  while (n > 0 && digits.length < 10) {
    digits.unshift(n % 10)
    n = Math.floor(n / 10)
  }

  return digits as Digit[]
}

export default numberToDigits
