import { useQuery } from '@tanstack/react-query'
import { IUser } from 'aupiq-pos-shared/src/schemas/User'

const useConnectedUser = () => {
  return useQuery<IUser>({
    queryKey: ['user'],
    enabled: false,
  })
}

export default useConnectedUser
