import { Language } from 'aupiq-pos-shared/src/schemas/Business'

const formatDate = (date: Date, language: Language): string => {
  return Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date)
}

export default formatDate
