import { Grid } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import SelectOrderItemsPanel from '../views/SelectOrderItemsPanel'
import TakeOrdersSidebar from '../views/TakeOrdersSidebar'

interface Props {
  sidebarView: ReactNode
  mainPanel: ReactNode
}

const TakeOrdersLayout: FC<Props> & { Loading: FC } = ({
  sidebarView,
  mainPanel,
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '280px 1fr',
      }}
      templateRows={{
        base: '1fr 1fr',
        md: '1fr',
      }}
      gap={2}
      overflow="hidden"
    >
      <Grid bgColor="gray.50" borderRadius="md" overflowY="hidden">
        {sidebarView}
      </Grid>
      {mainPanel}
    </Grid>
  )
}

TakeOrdersLayout.Loading = () => {
  return (
    <TakeOrdersLayout
      sidebarView={<TakeOrdersSidebar.Loading />}
      mainPanel={<SelectOrderItemsPanel.Loading />}
    />
  )
}

export default TakeOrdersLayout
