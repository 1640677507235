import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IProductCategory,
  IProductCategoryUpdateInput,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useUpdateProductCategory = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: user } = useConnectedUser()

  return useMutation<
    IProductCategory,
    DefaultError,
    { id: string; productCategory: IProductCategoryUpdateInput }
  >({
    mutationFn: async ({ id, productCategory }) => {
      return axios.patch(
        `/api/businesses/${user?.businessId}/categories/${id}`,
        productCategory,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'categories'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t(
          'product-category.errors.updating',
          'while updating the product category',
        ),
      })
    },
  })
}

export default useUpdateProductCategory
