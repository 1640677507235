import { Flex, Text } from '@chakra-ui/react'
import { FC, ReactElement } from 'react'
import DivButton from './DivButton'
import Modal from './Modal'
import HintText from './text/HintText'

type ActionMenuItem =
  | 'divider'
  | {
      title: string
      explainer?: string | ReactElement
      isDestructive?: boolean
      onClick: () => void
    }

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  items: ActionMenuItem[]
}

const ActionMenu: FC<Props> = ({ isOpen, onClose, title, items }) => {
  return (
    <Modal size="lg" title={title} isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" gap={4}>
        {items.map((item, i) => {
          return item === 'divider' ? (
            <hr key={i} />
          ) : (
            <DivButton
              key={i}
              theme={item.isDestructive ? 'danger' : 'default'}
              onClick={item.onClick}
            >
              <Text fontWeight="bold">{item.title}</Text>
              {item.explainer && <HintText>{item.explainer}</HintText>}
            </DivButton>
          )
        })}
      </Flex>
    </Modal>
  )
}

export default ActionMenu
