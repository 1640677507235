import { useQuery } from '@tanstack/react-query'
import {
  IProductCategory,
  productCategorySchema,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import axios from 'axios'
import { z } from 'zod'

import { QUERY_STALE_TIME } from '../../../constants'
import useConnectedUser from './useConnectedUser'

const useFetchCategories = () => {
  const { data: user } = useConnectedUser()

  return useQuery<IProductCategory[]>({
    staleTime: QUERY_STALE_TIME,
    enabled: Boolean(user),
    queryKey: ['business', user?.businessId, 'categories'],
    queryFn: () =>
      axios
        .get(`/api/businesses/${user?.businessId}/categories`)
        .then(res => z.array(productCategorySchema).parse(res.data)),
  })
}

export default useFetchCategories
