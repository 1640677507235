import { Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const HintText: FC<Props> = ({ children }: Props) => {
  return (
    <Text fontWeight="normal" fontSize="xs">
      {children}
    </Text>
  )
}

export default HintText
