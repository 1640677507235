import { z } from 'zod'

import { businessScopedModelSchema } from './abstract/BusinessScopedModel'
import { productSchema } from './Product'
import { userSchema } from './User'

const orderLineSchema = z.object({
  product: productSchema.pick({ id: true, name: true, price: true }),
  quantity: z.number().int().min(1),
})

export type IOrderLine = z.infer<typeof orderLineSchema>

export enum OrderStatus {
  Ongoing = 'Ongoing',
  Paid = 'Paid',
  Canceled = 'Canceled',
}

export enum OrderCancelingReason {
  CurstomerRequest = 'CurstomerRequest',
  InputError = 'InputError',
  DuplicateOrder = 'DuplicateOrder',
  SystemError = 'SystemError',
}

export const orderSchema = businessScopedModelSchema.extend({
  canceledAt: z.coerce.date().nullish(),
  cancelingReason: z.nativeEnum(OrderCancelingReason).nullish(),
  status: z.nativeEnum(OrderStatus),
  orderLines: z.array(orderLineSchema),
  createdByUserId: userSchema.shape.id,
  paidAmount: z.number().int().min(0),
})

export type IOrder = z.infer<typeof orderSchema>

export const orderCreateSchema = orderSchema.pick({
  status: true,
  orderLines: true,
  businessId: true,
  createdByUserId: true,
})

export type IOrderCreateInput = z.infer<typeof orderCreateSchema>

export const orderCreateRequestSchema = orderSchema.pick({
  status: true,
  orderLines: true,
})

export type IOrderCreateRequest = z.infer<typeof orderCreateRequestSchema>

export const orderCreateCommand = orderSchema.pick({
  status: true,
  orderLines: true,
  businessId: true,
  createdByUserId: true,
})

export type IOrderCreateCommand = z.infer<typeof orderCreateCommand>

export const orderUpdateSchema = orderSchema
  .pick({
    status: true,
    orderLines: true,
    canceledAt: true,
    cancelingReason: true,
  })
  .partial()

export type IOrderUpdateInput = z.infer<typeof orderUpdateSchema>
