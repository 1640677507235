import {
  Box,
  Flex,
  Grid,
  IconButton,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Language } from 'aupiq-pos-shared/src/schemas/Business'
import { OrderStatus } from 'aupiq-pos-shared/src/schemas/Order'
import { addDays, isSameDay } from 'date-fns'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import formatDate from '../../../services/format/date/formatDate'
import formatTime from '../../../services/format/time/formatTime'
import CurrencyAmount from '../../shared/components/currency/CurrencyAmount'
import OrderLinesList from '../../shared/components/OrderLinesList'
import OrderStatusTag from '../../shared/components/OrderStatusTag'
import PageLayout from '../../shared/layouts/PageLayout'
import getOrderTotal from '../../shared/utils/getOrderTotal'
import useFetchOrders from '../../take-orders/requests/useFetchOrders'

type StatusFilter = '' | OrderStatus

const now = new Date()

const ManageSalesHistoryPage: FC = () => {
  const { t, i18n } = useTranslation()

  const [dateFilter, setDateFilter] = useState<Date>(now)

  const [statusFilter, setStatusFilter] = useState<StatusFilter>('')

  const { isPending, data: orders } = useFetchOrders()

  const goToPreviousDay = () => {
    setDateFilter(dateFilter => addDays(dateFilter, -1))
  }

  const goToNextDay = () => {
    setDateFilter(dateFilter => addDays(dateFilter, 1))
  }

  const filteredOrders = useMemo(() => {
    const statusFilterFn = (status: OrderStatus): boolean => {
      if (statusFilter === '') {
        return true
      }
      return statusFilter === status
    }

    const dateFilterFn = (created_at: Date) => {
      return isSameDay(dateFilter, created_at)
    }

    return orders?.filter(
      order => dateFilterFn(order.created_at) && statusFilterFn(order.status),
    )
  }, [orders, dateFilter, statusFilter])

  if (isPending) {
    return <div>loading...</div>
  }

  if (!filteredOrders) {
    return <div>no data!</div>
  }

  return (
    <PageLayout title={t('pages.manage-sales-history', 'Sales Hisotry')}>
      <Flex gap={2}>
        <Flex>
          <IconButton
            variant="outline"
            aria-label="previous day"
            icon={<ArrowBackIcon />}
            onClick={goToPreviousDay}
          />
          <Grid
            minW={{
              base: '120px',
              md: '180px',
            }}
          >
            <SingleDatepicker
              date={dateFilter}
              configs={{
                dateFormat: 'dd/MM/yyyy',
                firstDayOfWeek: 1,
              }}
              onDateChange={setDateFilter}
            />
          </Grid>
          <IconButton
            variant="outline"
            aria-label="next day"
            icon={<ArrowForwardIcon />}
            onClick={goToNextDay}
          />
        </Flex>
        <Select
          w="auto"
          value={statusFilter}
          onChange={event =>
            setStatusFilter(event.target.value as StatusFilter)
          }
        >
          <option value="">{t('order.status.AnyStatus', 'Any status')}</option>
          <option value={OrderStatus.Ongoing}>
            {t(`order.status.${OrderStatus.Ongoing}`, 'Ongoing')}
          </option>
          <option value={OrderStatus.Paid}>
            {t(`order.status.${OrderStatus.Paid}`, 'Paid')}
          </option>
          <option value={OrderStatus.Canceled}>
            {t(`order.status.${OrderStatus.Canceled}`, 'Canceled')}
          </option>
        </Select>
      </Flex>
      <TableContainer overflowY="auto">
        <Table>
          <Thead position="sticky" top={0} bg="white">
            <Tr>
              <Th w={36}>{t('order.attributes.date.label', 'Date')}</Th>
              <Th w={24}>{t('order.attributes.time.label', 'Time')}</Th>
              <Th w={40}>{t('order.attributes.status.label', 'Status')}</Th>
              <Th w={40}>{t('order.attributes.total.label', 'Total')}</Th>
              <Th>{t('order.attributes.details.label', 'Details')}</Th>
            </Tr>
          </Thead>
          <Tbody verticalAlign="baseline">
            {filteredOrders.map(order => {
              return (
                <Tr key={order.id}>
                  <Td w={36}>
                    {formatDate(order.created_at, i18n.language as Language)}
                  </Td>
                  <Td w={24}>
                    {formatTime(order.created_at, i18n.language as Language)}
                  </Td>
                  <Td w={40}>
                    <OrderStatusTag status={order.status} />
                  </Td>
                  <Td w={40} isNumeric>
                    <CurrencyAmount amount={getOrderTotal(order)} />
                  </Td>
                  <Td maxW="sm">
                    <OrderLinesList orderLines={order.orderLines} />
                    {order.cancelingReason && (
                      <Box fontSize="xs" fontWeight="bold">
                        {t('order.cancel.reason.label', 'Reason')}:{' '}
                        {t(
                          `order.cancel.reason.${order.cancelingReason}`,
                          order.cancelingReason,
                        )}
                      </Box>
                    )}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </PageLayout>
  )
}

export default ManageSalesHistoryPage
