import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  VStack,
} from '@chakra-ui/react'
import { CurrencyPrecision } from 'aupiq-pos-shared/src/schemas/Business'
import {
  IProductCreateRequest,
  productCreateRequestSchema,
} from 'aupiq-pos-shared/src/schemas/Product'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { Field, FieldProps, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import formatCurrency from '../../../services/format/currency/formatCurrency'
import useFetchBusiness from '../../settings/requests/useFetchBusiness'
import CurrencySymbol from '../../shared/components/currency/CurrencySymbol'

const attrs: Record<
  keyof IProductCreateRequest,
  { id: keyof IProductCreateRequest; i18nId: string }
> = {
  categoryId: {
    id: 'categoryId',
    i18nId: 'product.attributes.category.label',
  },
  name: {
    id: 'name',
    i18nId: 'product.attributes.name.label',
  },
  price: {
    id: 'price',
    i18nId: 'product.attributes.price.label',
  },
}

interface Props {
  initialValues: IProductCreateRequest
  categories: IProductCategory[]
  onSubmit: (product: IProductCreateRequest) => Promise<void>
  submitButtonText: string
}

const ProductForm: FC<Props> = ({
  initialValues,
  categories,
  onSubmit,
  submitButtonText,
}) => {
  const { t } = useTranslation()

  const { data: business } = useFetchBusiness()

  return (
    <Formik<IProductCreateRequest>
      validationSchema={toFormikValidationSchema(productCreateRequestSchema)}
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit({ ...values, categoryId: values.categoryId || null })
      }}
    >
      {({ handleSubmit, errors, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isInvalid={Boolean(errors[attrs.categoryId.id])}>
              <FormLabel htmlFor={attrs.categoryId.id}>
                {t(attrs.categoryId.i18nId, 'Category')}
              </FormLabel>
              <Field
                as={Select}
                id={attrs.categoryId.id}
                name={attrs.categoryId.id}
              >
                <option value={undefined}></option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Field>
              <FormErrorMessage>{errors[attrs.categoryId.id]}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors[attrs.name.id])}>
              <FormLabel htmlFor={attrs.name.id}>
                {t(attrs.name.i18nId, 'Name')}
              </FormLabel>
              <Field as={Input} id={attrs.name.id} name={attrs.name.id} />
              <FormErrorMessage>{errors[attrs.name.id]}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors[attrs.price.id])}>
              <FormLabel htmlFor={attrs.price.id}>
                {t(attrs.price.i18nId, 'Price')}
              </FormLabel>
              <Field id={attrs.price.id} name={attrs.price.id}>
                {({ field }: FieldProps) => {
                  const currencyPrecision = !business
                    ? 2
                    : CurrencyPrecision[business.currency]
                  return (
                    <InputGroup>
                      <Input
                        {...field}
                        onChange={event => {
                          const newValue = event.target.value.replaceAll(
                            /\D/g,
                            '',
                          )
                          setFieldValue(field.name, Number(newValue))
                        }}
                        value={formatCurrency(field.value, currencyPrecision)}
                      />
                      {business?.currency && (
                        <InputRightAddon p={2}>
                          <CurrencySymbol currency={business.currency} />
                        </InputRightAddon>
                      )}
                    </InputGroup>
                  )
                }}
              </Field>
              <FormErrorMessage>{errors[attrs.price.id]}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              width="full"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              {submitButtonText}
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  )
}

export default ProductForm
