import {
  Button,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, forwardRef } from 'react'

enum Color {
  SkyBlue = '#8ecae6',
  OceanBlue = '#219ebc',
  MidnightBlue = '#023047',
  SunflowerYellow = '#ffb703',
  TangerineOrange = '#fb8500',
  TealLagoon = '#2a9d8f',
  MossyGreen = '#588157',
  Crimson = '#dc2f02',
}

interface ColorButtonProps {
  color?: Color
  onClick?: (color?: Color) => void
  name?: string
  onBlur?: () => void
}

const ColorButton = forwardRef<HTMLButtonElement, ColorButtonProps>(
  ({ color, onClick, name, onBlur }, ref) => {
    return (
      <Button
        ref={ref}
        height="full"
        _hover={{}}
        bgColor={color}
        onClick={() => onClick?.(color)}
        name={name}
        onBlur={onBlur}
      ></Button>
    )
  },
)

interface Props {
  value?: Color
  name?: string
  onChange: (color?: Color) => void
  onBlur?: () => void
}

const ColorPickerInput: FC<Props> = ({ value, onChange }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  const handleChange = (color?: Color) => {
    onChange(color)
    onClose()
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="end-end">
      <PopoverTrigger>
        <Grid w={16} h={16}>
          <ColorButton color={value} onClick={onToggle} />
        </Grid>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Grid
            gap={2}
            autoRows="var(--chakra-sizes-16)"
            templateColumns="repeat(auto-fill, var(--chakra-sizes-16))"
          >
            <ColorButton color={Color.SkyBlue} onClick={handleChange} />
            <ColorButton color={Color.OceanBlue} onClick={handleChange} />
            <ColorButton color={Color.MidnightBlue} onClick={handleChange} />
            <ColorButton color={Color.SunflowerYellow} onClick={handleChange} />
            <ColorButton color={Color.TangerineOrange} onClick={handleChange} />
            <ColorButton color={Color.TealLagoon} onClick={handleChange} />
            <ColorButton color={Color.MossyGreen} onClick={handleChange} />
            <ColorButton color={Color.Crimson} onClick={handleChange} />
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ColorPickerInput
