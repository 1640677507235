import { useQuery } from '@tanstack/react-query'
import { businessSchema } from 'aupiq-pos-shared/src/schemas/Business'
import axios from 'axios'

const useFetchBusinessById = (businessId: string) => {
  return useQuery({
    queryKey: ['businesses', businessId],
    queryFn: () =>
      axios
        .get(`/api/businesses/${businessId}`)
        .then(res => businessSchema.parse(res.data)),
  })
}

export default useFetchBusinessById
