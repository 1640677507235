import { useQuery } from '@tanstack/react-query'
import { IPayment, paymentSchema } from 'aupiq-pos-shared/src/schemas/Payment'
import axios from 'axios'
import { z } from 'zod'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useFetchPayments = (orderId?: string) => {
  const { data: user } = useConnectedUser()

  return useQuery<IPayment[]>({
    enabled: Boolean(user && orderId),
    queryKey: ['business', user?.businessId, 'paymentsByOrder', orderId],
    queryFn: () =>
      axios
        .get(`/api/businesses/${user?.businessId}/orders/${orderId}/payments`)
        .then(res => z.array(paymentSchema).parse(res.data)),
  })
}

export default useFetchPayments
