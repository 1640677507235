import { UserRole } from 'aupiq-pos-shared/src/schemas/User'

export const getIconForUserRole = (role: UserRole): string => {
  switch (role) {
    case UserRole.Admin:
      return '🗝'
    case UserRole.Manager:
      return '👑'
    case UserRole.Cashier:
      return '💲'
  }
}
