import { IProductCategoryCreateRequest } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ProductCategoryForm from './ProductCategoryForm'

interface Props {
  onSubmit: (productCategory: IProductCategoryCreateRequest) => Promise<void>
}

const CreateProductCategoryForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <ProductCategoryForm
      initialValues={{
        name: '',
        color: undefined,
      }}
      submitButtonText={t('common.actions.create', 'Create')}
      onSubmit={onSubmit}
    />
  )
}

export default CreateProductCategoryForm
