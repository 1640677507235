import { Tag } from '@chakra-ui/react'
import { OrderStatus } from 'aupiq-pos-shared/src/schemas/Order'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  status: OrderStatus
}

const statusToColor: Record<OrderStatus, string> = {
  [OrderStatus.Ongoing]: 'green',
  [OrderStatus.Paid]: 'yellow',
  [OrderStatus.Canceled]: 'red',
}

const OrderStatusTag: FC<Props> = ({ status }) => {
  const { t } = useTranslation()
  return (
    <Tag
      colorScheme={statusToColor[status]}
      fontSize="xs"
      minH="auto"
      px={1}
      py={0.5}
    >
      {t(`order.status.${status}`, status)}
    </Tag>
  )
}

export default OrderStatusTag
