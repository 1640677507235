import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'

const getProductsSortedByCategoryWithColors = (
  products: IProduct[],
  categories: IProductCategory[],
): { product: IProduct; color?: string }[] => {
  const categorizedProducts: Set<string> = new Set()
  return (
    categories
      .map(category =>
        products
          .filter(product => product.categoryId === category.id)
          .map(product => {
            categorizedProducts.add(product.id)
            return { product, color: category.color || undefined }
          }),
      )
      .flat()
      // list products that don't belong to any category last
      .concat(
        products
          .filter(product => !categorizedProducts.has(product.id))
          .map(product => ({ product, color: undefined })),
      )
  )
}

export default getProductsSortedByCategoryWithColors
