import { Flex, Text } from '@chakra-ui/react'
import { IOrder } from 'aupiq-pos-shared/src/schemas/Order'
import { FC, Fragment } from 'react'
import CircleIcon from './CircleIcon'

interface Props {
  orderLines: IOrder['orderLines']
}

const OrderLinesList: FC<Props> = ({ orderLines }) => {
  return (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      columnGap={2}
      fontSize="xs"
      fontWeight="normal"
    >
      {orderLines.map((orderLine, i) => {
        return (
          <Fragment key={orderLine.product.id}>
            <Text>
              {orderLine.product.name} x {orderLine.quantity}
            </Text>
            {i < orderLines.length - 1 && (
              <CircleIcon boxSize={1.5} color="gray.300" />
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default OrderLinesList
