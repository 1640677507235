import { Language } from 'aupiq-pos-shared/src/schemas/Business'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'

i18n.use(Backend).init({
  lng: localStorage.getItem('language') || Language.EN,
  fallbackLng: [Language.EN, Language.FR, Language.AR],
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
