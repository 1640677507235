import { z } from 'zod'

import { businessScopedModelSchema } from './abstract/BusinessScopedModel'
import { userSchema } from './User'

export enum PaymentMethod {
  Cash = 'Cash',
  BankCard = 'BankCard',
  RestaurantCheck = 'RestaurantCheck',
  Voucher = 'Voucher',
  OnAccount = 'OnAccount',
}

export enum PaymentStatus {
  Complete = 'Completed',
  Refunded = 'Refunded',
}

export const paymentSchema = businessScopedModelSchema.extend({
  orderId: z.string().uuid(),
  amount: z.number().int().positive(),
  method: z.nativeEnum(PaymentMethod),
  status: z.nativeEnum(PaymentStatus),
  createdByUserId: userSchema.shape.id,
})

export type IPayment = z.infer<typeof paymentSchema>

export const paymentCreateSchema = paymentSchema.pick({
  id: true,
  orderId: true,
  amount: true,
  method: true,
  status: true,
  businessId: true,
  createdByUserId: true,
})

export type IPaymentCreateInput = z.infer<typeof paymentCreateSchema>

export const paymentCreateRequestSchema = paymentSchema.pick({
  id: true,
  orderId: true,
  amount: true,
  method: true,
  status: true,
})

export type IPaymentCreateRequest = z.infer<typeof paymentCreateRequestSchema>
