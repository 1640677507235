import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import {
  businessCreateSchema,
  currencies,
  Currency,
  IBusinessCreateInput,
  Language,
  languages,
} from 'aupiq-pos-shared/src/schemas/Business'
import { Field, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import LanguageName from '../../shared/components/LanguageName'
import CurrencyName from '../../shared/components/currency/CurrencyName'

const attrs: Record<
  keyof IBusinessCreateInput,
  { id: keyof IBusinessCreateInput; i18nId: string }
> = {
  name: {
    id: 'name',
    i18nId: 'business.attributes.name.label',
  },
  language: {
    id: 'language',
    i18nId: 'business.attributes.language.label',
  },
  currency: {
    id: 'currency',
    i18nId: 'business.attributes.currency.label',
  },
}

const initialValues = {
  name: '',
  language: Language.FR,
  currency: Currency.TND,
}

interface Props {
  onSubmit: (config: IBusinessCreateInput) => Promise<void>
}

const SignupForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Formik<IBusinessCreateInput>
      validationSchema={toFormikValidationSchema(businessCreateSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" m="auto" gap={8} alignItems="center">
            <FormControl isInvalid={Boolean(errors[attrs.name.id])}>
              <FormLabel htmlFor={attrs.name.id}>
                {t(attrs.name.i18nId, 'Business name')}
              </FormLabel>
              <Field as={Input} id={attrs.name.id} name={attrs.name.id} />
              <FormErrorMessage>{errors[attrs.name.id]}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors[attrs.language.id])}>
              <FormLabel htmlFor={attrs.language.id}>
                {t(attrs.language.i18nId, 'Language')}
              </FormLabel>
              <Field
                as={Select}
                id={attrs.language.id}
                name={attrs.language.id}
              >
                {languages.map(language => (
                  <option key={language} value={language}>
                    <LanguageName language={language} />
                  </option>
                ))}
              </Field>
              <FormErrorMessage>{errors[attrs.language.id]}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors[attrs.currency.id])}>
              <FormLabel htmlFor={attrs.currency.id}>
                {t(attrs.currency.i18nId, 'Currency')}
              </FormLabel>
              <Field
                as={Select}
                disabled
                id={attrs.currency.id}
                name={attrs.currency.id}
              >
                {currencies.map(currency => (
                  <option key={currency} value={currency}>
                    <CurrencyName currency={currency} />
                  </option>
                ))}
              </Field>
              <FormErrorMessage>{errors[attrs.currency.id]}</FormErrorMessage>
            </FormControl>

            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              {t('common.actions.save')}
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  )
}

export default SignupForm
