import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IPaymentCreateRequest,
  PaymentMethod,
} from 'aupiq-pos-shared/src/schemas/Payment'
import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencyPrecision } from 'aupiq-pos-shared/src/schemas/Business'
import formatCurrency from '../../../../services/format/currency/formatCurrency'
import useFetchBusiness from '../../../settings/requests/useFetchBusiness'
import CurrencySymbol from '../../../shared/components/currency/CurrencySymbol'
import HintText from '../../../shared/components/text/HintText'
import digitsToNumber from '../../../shared/types/Digit/digitsToNumber'
import Numpad from '../../components/Numpad'
import PaymentMethodButton from '../../components/PaymentMethodButton'
import Digit from '../../types/Digit'

interface Props {
  amountStillToPay: number
  onPay: ({
    amount,
    method,
  }: Pick<IPaymentCreateRequest, 'amount' | 'method'>) => Promise<void>
}

const paymentMethods = [
  { icon: '💵', method: PaymentMethod.Cash },
  { icon: '💳', method: PaymentMethod.BankCard },
  { icon: '🧾', method: PaymentMethod.RestaurantCheck },
  // { icon: '🎫', method: PaymentMethod.Voucher },
  // { icon: '📖', method: PaymentMethod.OnAccount },
]

const MakePaymentPanel: FC<Props> = ({ amountStillToPay, onPay }) => {
  const { t } = useTranslation()

  const { data: business } = useFetchBusiness()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(undefined)

  const [amountProvidedDigits, setAmountProvidedDigits] = useState<Digit[]>([])
  const [isPaying, setIsPaying] = useState(false)

  const amountProvided = useMemo(() => {
    return digitsToNumber(amountProvidedDigits)
  }, [amountProvidedDigits])

  const amountToPay = amountProvided || amountStillToPay

  const {
    isOpen: isConfirmPaymentDialogOpen,
    onOpen: onOpenConfirmPaymentDialog,
    onClose: onCloseConfirmPaymentDialog,
  } = useDisclosure()

  const cancelConfirmPaymentButtonRef = useRef(null)

  const inputNewDigitToAmountProvided = (digit: Digit) => {
    setAmountProvidedDigits(amountProvidedDigits => {
      // don't allow leading zeros
      if (amountProvidedDigits.length === 0 && digit === 0) {
        return amountProvidedDigits
      }
      return [...amountProvidedDigits, digit]
    })
  }

  const removeDigitFromAmountProvided = () => {
    setAmountProvidedDigits(amountProvidedDigits =>
      amountProvidedDigits.slice(0, -1),
    )
  }

  const clearAmountProvidedDigits = () => {
    setAmountProvidedDigits([])
  }

  const handleOnPay = async (paymentMethod: PaymentMethod) => {
    try {
      await onPay({
        amount: amountToPay,
        method: paymentMethod,
      })
      clearAmountProvidedDigits()
    } catch (err) {
      /* empty */
    }
  }

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isConfirmPaymentDialogOpen}
        leastDestructiveRef={cancelConfirmPaymentButtonRef}
        onClose={onCloseConfirmPaymentDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('payment.confirm-payment.title', 'Confirm payment')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {selectedPaymentMethod &&
                t(
                  'payment.confirm-payment.message',
                  'Are you sure you want to pay the amount {{amount}} using {{paymentMethod}}',
                  {
                    amount: formatCurrency(
                      amountToPay,
                      !business?.currency
                        ? 2
                        : CurrencyPrecision[business.currency],
                    ),
                    paymentMethod: t(
                      `payment.methods.${selectedPaymentMethod}`,
                    ),
                  },
                )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelConfirmPaymentButtonRef}
                onClick={onCloseConfirmPaymentDialog}
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                colorScheme="yellow"
                isLoading={isPaying}
                onClick={async () => {
                  if (!selectedPaymentMethod) {
                    return
                  }
                  setIsPaying(true)
                  try {
                    await handleOnPay(selectedPaymentMethod)
                  } catch (err) {
                    /* empty*/
                  } finally {
                    setIsPaying(false)
                  }
                  onCloseConfirmPaymentDialog()
                }}
                ml={3}
              >
                {t('common.actions.pay', 'Pay')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Grid templateColumns={{ base: '1fr', md: '1fr 200px' }} gap={8} flex="1">
        <Grid
          templateRows="auto 1fr"
          gap={4}
          marginInline="auto"
          maxW={400}
          maxH={400}
        >
          <Grid
            gap={1}
            templateColumns="1fr 1fr"
            templateRows="auto auto"
            fontSize="xs"
          >
            <Text>{t('payment.amount-left-to-pay.label', 'To pay')}</Text>
            <Text align="end">
              {t('payment.amount-provided.label', 'Amount provided')}
            </Text>
            <InputGroup>
              <Input
                px={2}
                data-testid="MakePaymentPanel-AmountStillToPay"
                fontWeight="bold"
                readOnly
                value={formatCurrency(
                  amountStillToPay,
                  !business?.currency
                    ? 2
                    : CurrencyPrecision[business.currency],
                )}
              ></Input>
              {business?.currency && (
                <InputRightAddon p={2}>
                  <CurrencySymbol currency={business.currency} />
                </InputRightAddon>
              )}
            </InputGroup>
            <InputGroup>
              <Input
                px={2}
                readOnly
                fontWeight="bold"
                textAlign="end"
                data-testid="payment.amount-provided.input"
                placeholder={t(
                  'payment.amount-provided.placeholder',
                  'Leave blank to pay all',
                )}
                value={
                  amountProvided
                    ? formatCurrency(
                        amountProvided,
                        !business?.currency
                          ? 2
                          : CurrencyPrecision[business.currency],
                      )
                    : ''
                }
              />
              {business?.currency && (
                <InputRightAddon p={2}>
                  <CurrencySymbol currency={business.currency} />
                </InputRightAddon>
              )}
            </InputGroup>
          </Grid>
          <Numpad
            onInputDigit={inputNewDigitToAmountProvided}
            onBackspace={removeDigitFromAmountProvided}
            onClear={clearAmountProvidedDigits}
          />
        </Grid>
        <Box
          display={{
            base: 'grid',
            md: 'flex',
          }}
          flexDirection="column"
          gridTemplateColumns="1fr 1fr"
          gap={2}
        >
          <GridItem colSpan={2}>
            <HintText>
              {t(
                'payment.payment-method-hint',
                'Choose payment method to pay, or double-tap to confirm and pay',
              )}
            </HintText>
          </GridItem>
          {paymentMethods.map(({ icon, method }) => {
            return (
              <PaymentMethodButton
                key={method}
                icon={icon}
                name={method}
                isLoading={isPaying}
                onClick={() => {
                  setSelectedPaymentMethod(method)
                  onOpenConfirmPaymentDialog()
                }}
                onDoubleClick={async () => {
                  setIsPaying(true)
                  try {
                    await handleOnPay(method)
                  } catch (err) {
                    /* empty*/
                  } finally {
                    setIsPaying(false)
                  }
                }}
              />
            )
          })}
        </Box>
      </Grid>
    </>
  )
}

export default MakePaymentPanel
