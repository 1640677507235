import { Flex, Grid, Heading } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import ProductCategoryListLoading from '../components/ProductCategoryListLoading'
import ProductListLoading from '../components/ProductListLoading'

interface Props {
  categoriesView: ReactNode
  productsView: ReactNode
}

const ProductsAndCategoriesLayout: FC<Props> & { Loading: FC } = ({
  categoriesView,
  productsView,
}) => {
  const { t } = useTranslation()
  return (
    <Grid
      templateColumns={{ base: '120px 1fr', md: '160px 1fr' }}
      overflowY="hidden"
      gap={2}
    >
      <Flex
        direction="column"
        gap={{
          base: 2,
          md: 4,
        }}
        overflowY="hidden"
        padding={1}
      >
        <Heading
          textTransform="capitalize"
          color="gray.400"
          fontWeight="medium"
        >
          {t('common.categories', 'categories')}
        </Heading>
        {categoriesView}
      </Flex>
      <Flex
        direction="column"
        gap={{
          base: 2,
          md: 4,
        }}
        overflowY="hidden"
        padding={1}
      >
        <Heading
          textTransform="capitalize"
          color="gray.400"
          fontWeight="medium"
        >
          {t('common.products', 'products')}
        </Heading>
        {productsView}
      </Flex>
    </Grid>
  )
}

ProductsAndCategoriesLayout.Loading = () => {
  return (
    <div data-testid="ProductsAndCategoriesLayout-Loading">
      <ProductsAndCategoriesLayout
        categoriesView={<ProductCategoryListLoading />}
        productsView={<ProductListLoading />}
      />
    </div>
  )
}

export default ProductsAndCategoriesLayout
