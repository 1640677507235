import { useDisclosure } from '@chakra-ui/react'
import { IPaymentCreateRequest } from 'aupiq-pos-shared/src/schemas/Payment'

import usePayOrder from '../../requests/usePayOrder'

const usePaymentProcessing = () => {
  // State declarations
  const {
    isOpen: isPaymentPanelOpen,
    onOpen: openPaymentPanel,
    onClose: closePaymentPanel,
  } = useDisclosure()

  // External hooks and queries
  const payOrder = usePayOrder()

  // Utility functions
  const payAndSaveOrder = async (paymentDetails: IPaymentCreateRequest) => {
    await payOrder.mutateAsync(paymentDetails)
  }

  return {
    isPaymentPanelOpen,
    openPaymentPanel,
    closePaymentPanel,
    payAndSaveOrder,
  }
}

export default usePaymentProcessing
