import { IBusinessUpdateInput } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import PageLayout from '../../shared/layouts/PageLayout'
import useConnectedUser from '../../shared/requests/useConnectedUser'
import SettingsForm from '../components/SettingsForm'
import useFetchBusiness from '../requests/useFetchBusiness'
import useUpdateBusiness from '../requests/useUpdateBusiness'

const SettingsPage: FC = () => {
  const { t } = useTranslation()

  const updateSettings = useUpdateBusiness()

  const { data: user } = useConnectedUser()

  const { data: settings, isLoading } = useFetchBusiness()

  const onSubmit = async (settings: IBusinessUpdateInput) => {
    if (!user?.businessId) {
      return
    }

    await updateSettings.mutateAsync({
      id: user.businessId,
      business: settings,
    })
  }

  if (isLoading) {
    return <div>loading...</div>
  }

  return (
    <PageLayout title={t('pages.settings', 'Settings')}>
      <SettingsForm initialValues={settings} onSubmit={onSubmit} />
    </PageLayout>
  )
}

export default SettingsPage
