import { useQuery } from '@tanstack/react-query'
import { userWithoutPasswordSchema } from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { z } from 'zod'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useFetchUsers = () => {
  const { data: user } = useConnectedUser()

  return useQuery({
    queryKey: ['business', user?.businessId, 'users'],
    enabled: Boolean(user),
    queryFn: () =>
      axios
        .get(`/api/businesses/${user?.businessId}/users`)
        .then(res => z.array(userWithoutPasswordSchema).parse(res.data)),
  })
}

export default useFetchUsers
