import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import {
  IBusiness,
  IBusinessUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Business'

const useUpdateBusiness = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation<
    IBusiness,
    DefaultError,
    { id: string; business: IBusinessUpdateInput }
  >({
    mutationFn: async ({ id, business }) => {
      return axios.patch(`/api/businesses/${id}/`, business)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['businesses'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('business.edit.error', 'while updating the business'),
      })
    },
  })
}

export default useUpdateBusiness
