import { Box, Flex, Grid } from '@chakra-ui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { IUser, permissionsByRole } from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'

import './App.css'
import AppRoute, {
  guestRoutes,
  routes,
  routesConfig,
} from './features/app/AppRoute'
import MainMenu from './features/app/components/MainMenu'
import StatusBar from './features/app/components/StatusBar'
import ManageBusinessUsersPage from './features/manage-businesses/pages/ManageBusinessUsersPage'
import useFetchBusiness from './features/settings/requests/useFetchBusiness'

function App() {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const [lang, setLang] = useState(i18n.language)
  const [dir, setDir] = useState(i18n.dir())

  i18n.on('languageChanged', (lng: string) => {
    setLang(lng)
    setDir(i18n.dir(lng))
  })

  const { data: user } = useQuery<IUser>({
    queryKey: ['user'],
    enabled: false,
  })

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      queryClient.setQueryData(['user'], jwtDecode(token))
    }
  }, [queryClient, user])

  const userPermissions = user?.role && permissionsByRole[user.role]

  const { data: settings } = useFetchBusiness()

  const logout = () => {
    localStorage.removeItem('authToken')
    queryClient.removeQueries({ queryKey: ['user'] })
    delete axios.defaults.headers.common.Authorization
    location.assign('/login')
  }

  useEffect(() => {
    if (!settings?.language) {
      return
    }
    localStorage.setItem('language', settings.language)
  }, [settings?.language])

  useEffect(() => {
    if (settings?.language) {
      i18n.changeLanguage(settings?.language)
    }
  }, [i18n, settings?.language])

  useEffect(() => {
    document.documentElement.lang = lang
    document.dir = dir
  }, [lang, dir])

  return (
    <Box
      height="100%"
      bg="gray.50"
      overflow="hidden"
      lineHeight="normal"
      fontSize={{
        base: '12px',
        md: '14px',
      }}
    >
      <Flex
        direction="column"
        p={0}
        height="100%"
        bg="white"
        borderRadius="md"
        overflow="hidden"
      >
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
        <Grid
          templateColumns={{
            base: '1fr',
            md: '70px 1fr',
          }}
          templateRows={{
            base: 'auto 1fr',
            md: '1fr',
          }}
          p={1}
          gap={2}
          flex={1}
          overflowY="hidden"
        >
          {user ? (
            <Box overflowY="auto">
              <MainMenu onLogout={logout} />
            </Box>
          ) : (
            <div></div>
          )}
          {!user && (
            <Switch>
              {guestRoutes.map(routeId => {
                const route = routesConfig[routeId]
                return (
                  <Route key={route.id} path={'/' + route.id}>
                    {route.page}
                  </Route>
                )
              })}
              <Route>
                <Redirect
                  to={'/' + routesConfig[AppRoute.Login].id}
                  push={false}
                />
              </Route>
            </Switch>
          )}
          {user && (
            <Switch>
              {routes
                .filter(route => {
                  const requriedPermission =
                    routesConfig[route].requiredPermission
                  if (!requriedPermission) {
                    return true
                  }
                  return userPermissions?.[requriedPermission] === true
                })
                .map(route => {
                  return (
                    <Route
                      key={routesConfig[route].id}
                      exact={routesConfig[route].isExact}
                      path={'/' + routesConfig[route].id}
                    >
                      {routesConfig[route].page}
                    </Route>
                  )
                })}
              {userPermissions?.ManageBusinesses && (
                <Route
                  path={`/${routesConfig[AppRoute.ManageBusinesses].id}/:businessId`}
                >
                  <ManageBusinessUsersPage />
                </Route>
              )}
              <Route>
                <Redirect
                  to={'/' + routesConfig[AppRoute.TakeOrders].id}
                  push={false}
                />
              </Route>
            </Switch>
          )}
        </Grid>
        {user && <StatusBar />}
      </Flex>
    </Box>
  )
}

export default App
