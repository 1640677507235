import { z } from 'zod'

import { baseModelSchema } from './abstract/BaseModel'

export enum Language {
  EN = 'en-uk',
  FR = 'fr',
  AR = 'ar',
}

export const languages: Language[] = [Language.FR, Language.EN, Language.AR]

export enum Currency {
  EUR = 'EUR',
  TND = 'TND',
}

export const currencies: Currency[] = [Currency.TND, Currency.EUR]

export const CurrencyPrecision: Record<Currency, number> = {
  [Currency.EUR]: 2,
  [Currency.TND]: 3,
}

// TODO: support different payment methods

export const businessSchema = baseModelSchema.extend({
  name: z.string().trim().min(1),
  language: z.nativeEnum(Language),
  currency: z.nativeEnum(Currency),
})

export type IBusiness = z.infer<typeof businessSchema>

export const businessCreateSchema = businessSchema.pick({
  name: true,
  language: true,
  currency: true,
})

export type IBusinessCreateInput = z.infer<typeof businessCreateSchema>

export const businessUpdateSchema = businessSchema
  .pick({
    name: true,
    language: true,
  })
  .partial()

export type IBusinessUpdateInput = z.infer<typeof businessUpdateSchema>
