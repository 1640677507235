import {
  BrowserOptions,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react'

const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'app.aupiq.com':
      return 'production'
    case 'preprod.aupiq.com':
      return 'preprod'
    default:
      return 'development'
  }
}

const sentryConfig: BrowserOptions = {
  environment: getEnvironment(),
  dsn: 'https://97d441b24e61cd1acb4c3f441e4c04e8@o4506937693896704.ingest.us.sentry.io/4508404840726528',
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      networkCaptureBodies: true,
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 0 : 1,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}

export default sentryConfig
