import { CheckCircleIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IOrder,
  OrderCancelingReason,
  OrderStatus,
} from 'aupiq-pos-shared/src/schemas/Order'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ChooseCancelingReasonDialog from './ChooseCancelingReasonDialog'

import OrderStatusTag from '../../../../shared/components/OrderStatusTag'
import getOrderTotal from '../../../../shared/utils/getOrderTotal'

import { Language } from 'aupiq-pos-shared/src/schemas/Business'
import formatAdaptiveDateTime from '../../../../../services/format/date-time/formatAdaptiveDateTime'
import CurrencyAmount from '../../../../shared/components/currency/CurrencyAmount'
import Modal from '../../../../shared/components/Modal'
import useOrderValidation from '../../../pages/hooks/useOrderValidation'
import useFetchPayments from '../../../requests/useFetchPayments'
import EditProductQuantityForm from './EditProductQuantityForm'
import OrderLine from './OrderLine'

const OngoingActions: FC<{
  onPayOrder: () => void
  onCancelOrder: () => void
}> = ({ onPayOrder, onCancelOrder }) => {
  const { t } = useTranslation()

  return (
    <Flex gap={2} alignItems="flex-end">
      <Flex direction="column" flex={1} gap={2}>
        <Button colorScheme="yellow" onClick={onPayOrder} gap={2}>
          <CheckCircleIcon />
          {t('order.pay.action', 'Pay')}
        </Button>
      </Flex>
      <IconButton
        px={4}
        aria-label={t('order.cancel.action')}
        colorScheme="gray"
        icon={<DeleteIcon />}
        onClick={onCancelOrder}
      />
    </Flex>
  )
}

interface Props {
  order: IOrder
  selectedOrderLineProductId?: IProduct['id']
  onClickOrderLine: (orderLineProductId: IProduct['id']) => void
  onUpdateProductQuantity: (quantity: number) => void
  onCancelOrder: (cancelingReason: OrderCancelingReason) => void
  onOpenPaymentPanel: () => void
  onClose: () => void
}

const OrderDetailsPanel: FC<Props> = ({
  order,
  selectedOrderLineProductId,
  onClickOrderLine,
  onUpdateProductQuantity,
  onOpenPaymentPanel,
  onCancelOrder,
  onClose,
}) => {
  const { t, i18n } = useTranslation()
  const { validateOrderUpdate } = useOrderValidation()

  const {
    isOpen: isEditQuantityModalOpen,
    onOpen: onOpenEditQuantityModal,
    onClose: onCloseEditQuantityModal,
  } = useDisclosure()

  const {
    isOpen: isCancelingReasonDialogOpen,
    onClose: closeCancelingReasonDialog,
    onOpen: openCancelingReasonDialog,
  } = useDisclosure()

  const selectedOrderline = order.orderLines.find(
    orderLine => orderLine.product.id === selectedOrderLineProductId,
  )

  const paymentsQuery = useFetchPayments(order.id)

  const productName = selectedOrderline?.product.name
  const quantity = selectedOrderline?.quantity

  const orderTotal = getOrderTotal(order)

  const amountStillToPay = orderTotal - order.paidAmount

  return (
    <>
      <Modal
        title={t('order.cancel.action', 'Cancel order')}
        isOpen={isCancelingReasonDialogOpen}
        onClose={closeCancelingReasonDialog}
      >
        <ChooseCancelingReasonDialog
          onChoose={cancelingReason => {
            onCancelOrder(cancelingReason)
            closeCancelingReasonDialog()
          }}
        />
      </Modal>
      <Modal
        title={`${t('order.edit-quantity.title', 'Edit quantity')} - ${productName}`}
        size="sm"
        isOpen={isEditQuantityModalOpen}
        onClose={onCloseEditQuantityModal}
      >
        <EditProductQuantityForm
          initialQuantity={quantity || 0}
          onConfirm={quantity => {
            onUpdateProductQuantity(quantity)
            onCloseEditQuantityModal()
          }}
          onCancel={onCloseEditQuantityModal}
        />
      </Modal>
      <Grid
        data-testid="OrderDetailsPanel"
        templateRows="auto 1fr auto"
        gap={1}
        overflowY="hidden"
      >
        <Grid templateColumns="auto 1fr auto" gap={2} alignItems="center">
          <Heading
            data-testid="OrderDetailsPanel-title"
            textTransform="capitalize"
          >
            {formatAdaptiveDateTime(
              order.created_at,
              i18n.language as Language,
            )}
          </Heading>
          <Flex alignItems="center">
            <OrderStatusTag status={order.status} />
          </Flex>
          <IconButton
            size="lg"
            aria-label="Close order"
            icon={<CloseIcon />}
            onClick={onClose}
          />
        </Grid>
        <Box overflowY="scroll">
          <Flex direction="column" gap={2} p={0.5}>
            {order.orderLines.map(orderLine => {
              return (
                <OrderLine
                  key={orderLine.product.id}
                  orderLine={orderLine}
                  onClick={orderlineId => {
                    if (!validateOrderUpdate(order)) {
                      return
                    }
                    onClickOrderLine(orderlineId)
                    onOpenEditQuantityModal()
                  }}
                />
              )
            })}
          </Flex>
          {(paymentsQuery.data?.length || 0) > 0 && (
            <Flex
              direction="column"
              gap={4}
              pt={4}
              mt={4}
              borderTopWidth={1}
              borderTopStyle="solid"
              borderTopColor="gray.500"
            >
              <Heading fontSize="sm">
                {t('order.transactions.title', 'Payments')}
              </Heading>
              {paymentsQuery.data?.map(payment => {
                return (
                  <Flex key={payment.id}>
                    <CurrencyAmount amount={payment.amount} /> -{' '}
                    {t(`payment.methods.${payment.method}`)}
                  </Flex>
                )
              })}
            </Flex>
          )}
        </Box>
        {paymentsQuery.data && paymentsQuery.data.length > 0 && <Box></Box>}
        <Box px={2} py={1} borderTopColor="gray.500" borderTopWidth={2}>
          {order.paidAmount > 0 && (
            <Flex
              data-testid="OrderDetailsPanel-OrderTotal"
              justifyContent="end"
              textDecoration="line-through"
              textDecorationColor="gray.400"
            >
              <CurrencyAmount amount={orderTotal} />
            </Flex>
          )}
          <Flex gap={4} justify="flex-end" fontSize="xl" fontWeight="semibold">
            <Text textTransform="uppercase">{t('order.total', 'TOTAL')}</Text>

            <Text data-testid="OrderDetailsPanel-AmountStillToPay">
              <CurrencyAmount amount={amountStillToPay} />
            </Text>
          </Flex>
        </Box>
        {order.status === OrderStatus.Ongoing && (
          <OngoingActions
            onPayOrder={onOpenPaymentPanel}
            onCancelOrder={openCancelingReasonDialog}
          />
        )}
      </Grid>
    </>
  )
}

export default OrderDetailsPanel
