import { Grid } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ProductListComposite: FC<Props> = ({ children }) => {
  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(180px, 1fr))"
      autoRows="auto"
      gap={{
        base: 2,
        md: 4,
      }}
    >
      {children}
    </Grid>
  )
}

export default ProductListComposite
