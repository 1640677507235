import { Box } from '@chakra-ui/react'
import { FC } from 'react'

const ColorBar: FC<{ color?: string }> = ({ color }) => {
  return (
    <Box
      style={{
        insetInlineStart: 0,
      }}
      bgColor={color}
      position="absolute"
      top={0}
      bottom={0}
      width={1.5}
    />
  )
}

export default ColorBar
