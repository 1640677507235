import { useQuery } from '@tanstack/react-query'
import { IOrder, orderSchema } from 'aupiq-pos-shared/src/schemas/Order'
import axios from 'axios'
import { z } from 'zod'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useFetchOrders = () => {
  const { data: user } = useConnectedUser()

  return useQuery<IOrder[]>({
    enabled: Boolean(user),
    queryKey: ['business', user?.businessId, 'orders'],
    queryFn: () =>
      axios
        .get(`/api/businesses/${user?.businessId}/orders`)
        .then(res => z.array(orderSchema).parse(res.data)),
  })
}

export default useFetchOrders
