import { Grid } from '@chakra-ui/react'

import DivButton from '../../shared/components/DivButton'
import Digit from '../types/Digit'

const NumpadButton = ({
  label,
  onClick,
  isDanger,
}: {
  label: string
  onClick: () => void
  isDanger?: boolean
}) => {
  return (
    <DivButton
      theme="subtle"
      p={0}
      display="grid"
      justifyContent="center"
      textColor={isDanger ? 'red.400' : undefined}
      onClick={onClick}
    >
      {label}
    </DivButton>
  )
}

interface NumpadProps {
  onInputDigit: (digit: Digit) => void
  onBackspace: () => void
  onClear: () => void
}

const Numpad = ({ onInputDigit, onBackspace, onClear }: NumpadProps) => {
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(5, 1fr)"
      fontSize="3xl"
      gap={2}
    >
      <NumpadButton
        label="1"
        onClick={() => {
          onInputDigit(1)
        }}
      />
      <NumpadButton
        label="2"
        onClick={() => {
          onInputDigit(2)
        }}
      />
      <NumpadButton
        label="3"
        onClick={() => {
          onInputDigit(3)
        }}
      />
      <NumpadButton
        label="4"
        onClick={() => {
          onInputDigit(4)
        }}
      />
      <NumpadButton
        label="5"
        onClick={() => {
          onInputDigit(5)
        }}
      />
      <NumpadButton
        label="6"
        onClick={() => {
          onInputDigit(6)
        }}
      />
      <NumpadButton
        label="7"
        onClick={() => {
          onInputDigit(7)
        }}
      />
      <NumpadButton
        label="8"
        onClick={() => {
          onInputDigit(8)
        }}
      />
      <NumpadButton
        label="9"
        onClick={() => {
          onInputDigit(9)
        }}
      />
      <NumpadButton
        label="0"
        onClick={() => {
          onInputDigit(0)
        }}
      />
      <NumpadButton
        label="00"
        onClick={() => {
          onInputDigit(0)
          onInputDigit(0)
        }}
      />
      <NumpadButton
        label="000"
        onClick={() => {
          onInputDigit(0)
          onInputDigit(0)
          onInputDigit(0)
        }}
      />
      <div></div>
      <NumpadButton label="C" isDanger onClick={() => onClear()} />
      <NumpadButton label="⬅️" onClick={() => onBackspace()} />
    </Grid>
  )
}

export default Numpad
