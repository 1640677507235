import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react'
import {
  IProductCategoryCreateRequest,
  productCategoryCreateRequestSchema,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { Field, FieldConfig, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import ColorPickerInput from './ColorPickerInput'

interface Props {
  initialValues: IProductCategoryCreateRequest
  submitButtonText: string
  onSubmit: (productCategory: IProductCategoryCreateRequest) => Promise<void>
}

const ProductCategoryForm: FC<Props> = ({
  initialValues,
  submitButtonText,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <Formik<IProductCategoryCreateRequest>
      validationSchema={toFormikValidationSchema(
        productCategoryCreateRequestSchema,
      )}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel htmlFor="name">
                {t('product-category.attributes.name.label', 'Name')}
              </FormLabel>
              <Field as={Input} id="name" name="name" />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.color)}>
              <FormLabel htmlFor="color">
                {' '}
                {t('product-category.attributes.color.label', 'Color')}
              </FormLabel>
              {/* <Field as={ColorPickerInput} id="color" name="color" /> */}
              <Field name="color">
                {
                  (({ field: { value, name }, form: { setFieldValue } }) => (
                    <ColorPickerInput
                      value={value}
                      onChange={color => {
                        setFieldValue(name, color)
                      }}
                    />
                  )) as FieldConfig['children']
                }
              </Field>
              <FormErrorMessage>{errors.color}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              width="full"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              {submitButtonText}
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  )
}

export default ProductCategoryForm
