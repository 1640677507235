import { CurrencyPrecision } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import formatCurrency from '../../../../services/format/currency/formatCurrency'
import useFetchBusiness from '../../../settings/requests/useFetchBusiness'
import CurrencySymbol from './CurrencySymbol'

interface Props {
  amount: number
}

const CurrencyAmount: FC<Props> = ({ amount }) => {
  const { data: business } = useFetchBusiness()

  if (!business?.currency) {
    return
  }

  const currencyPrecision = CurrencyPrecision[business.currency]

  return (
    <>
      {formatCurrency(amount, currencyPrecision)}{' '}
      <CurrencySymbol currency={business.currency} />
    </>
  )
}

export default CurrencyAmount
