import { Divider, Flex, Text } from '@chakra-ui/react'
import { IUserWithoutPassword } from 'aupiq-pos-shared/src/schemas/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getIconForUserRole } from '../utils/getIconForUserRole'
import DivButton from './DivButton'

interface Props {
  user: IUserWithoutPassword
  businessName?: string
  isCurrentUser?: boolean
  onClick?: () => void
}

const UserCard: FC<Props> = ({
  user,
  businessName,
  isCurrentUser,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <DivButton
      theme="subtle"
      display="grid"
      gridTemplateColumns="auto auto 1fr"
      gap="2"
      textDecorationColor="red.400"
      textDecorationLine={user.isActive ? undefined : 'line-through'}
      onClick={onClick}
    >
      <Flex direction="column" alignItems="center">
        <Text fontSize="4xl">👤</Text>
        <Flex alignItems="center" gap="1">
          <Text as="span" fontSize="md">
            {getIconForUserRole(user.role)}
          </Text>
          <Text as="span" fontSize="xs">
            {t(`user.role.${user.role}`, user.role)}
          </Text>
        </Flex>
      </Flex>
      <Divider orientation="vertical" />
      <Flex direction="column">
        <Text fontWeight="semibold">
          {user.username} {isCurrentUser && `(${t('user.isMe.label', 'me')})`}
        </Text>
        {businessName && <Text>{businessName}</Text>}
      </Flex>
    </DivButton>
  )
}

export default UserCard
