import { Permission } from 'aupiq-pos-shared/src/schemas/User'
import { ReactElement } from 'react'
import AdminManageUsersPage from '../admin-users/pages/AdminManageUsersPage'
import ManageBusinessesPage from '../manage-businesses/pages/ManageBusinessesPage'
import ManageProductsPage from '../manage-products/pages/ManageProductsPage'
import ManageSalesHistoryPage from '../manage-sales-history/pages/ManageSalesHistoryPage'
import ManageUsersPage from '../manage-users/pages/ManageUsersPage'
import SettingsPage from '../settings/pages/SettingsPage'
import TakeOrdersPage from '../take-orders/pages/TakeOrdersPage'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'

enum AppRoute {
  TakeOrders = 'take-orders',
  ManageProducts = 'manage-products',
  Settings = 'settings',
  ManageUsers = 'manage-users',
  ManageBusinesses = 'manage-businesses',
  ManageAllUsers = 'manage-all-users',
  ManageSalesHistory = 'manage-sales-history',
  Login = 'login',
  Signup = 'signup',
}

interface RouteConfig {
  id: string
  icon: string
  defaultName: string
  // false for "no permission required", i.e route always visible
  requiredPermission: Permission | false
  page: ReactElement
  isExact?: boolean
}

export const guestRoutes: AppRoute[] = [AppRoute.Login, AppRoute.Signup]

export const routes: AppRoute[] = [
  AppRoute.TakeOrders,
  AppRoute.ManageSalesHistory,
  AppRoute.ManageProducts,
  AppRoute.ManageUsers,
  AppRoute.ManageBusinesses,
  AppRoute.ManageAllUsers,
  AppRoute.Settings,
]

export const routesConfig: { [K in AppRoute]: RouteConfig } = {
  [AppRoute.Login]: {
    id: AppRoute.Login,
    icon: '',
    defaultName: '',
    requiredPermission: false,
    page: <LoginPage />,
  },
  [AppRoute.Signup]: {
    id: AppRoute.Signup,
    icon: '',
    defaultName: '',
    requiredPermission: false,
    page: <SignupPage />,
  },
  [AppRoute.TakeOrders]: {
    id: AppRoute.TakeOrders,
    icon: '📝',
    defaultName: 'Orders',
    requiredPermission: false,
    page: <TakeOrdersPage />,
  },
  [AppRoute.ManageSalesHistory]: {
    id: AppRoute.ManageSalesHistory,
    icon: '📚',
    defaultName: 'Sales Hisotry',
    requiredPermission: Permission.ManageSalesHistory,
    page: <ManageSalesHistoryPage />,
  },
  [AppRoute.ManageProducts]: {
    id: AppRoute.ManageProducts,
    icon: '📦',
    defaultName: 'Products',
    requiredPermission: Permission.ManageProductsAndCategories,
    page: <ManageProductsPage />,
  },
  [AppRoute.ManageUsers]: {
    id: AppRoute.ManageUsers,
    icon: '👥',
    defaultName: 'Staff',
    requiredPermission: Permission.ManageUsers,
    page: <ManageUsersPage />,
  },
  [AppRoute.ManageBusinesses]: {
    id: AppRoute.ManageBusinesses,
    icon: '🏢',
    defaultName: 'Businesses',
    requiredPermission: Permission.ManageBusinesses,
    page: <ManageBusinessesPage />,
    isExact: true,
  },
  [AppRoute.ManageAllUsers]: {
    id: AppRoute.ManageAllUsers,
    icon: '🌐👥',
    defaultName: 'Users',
    requiredPermission: Permission.ManageAllUsers,
    page: <AdminManageUsersPage />,
  },
  [AppRoute.Settings]: {
    id: AppRoute.Settings,
    icon: '⚙️',
    defaultName: 'Settings',
    requiredPermission: Permission.ManageSettings,
    page: <SettingsPage />,
  },
}

export default AppRoute
