import { Flex, Heading } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  title: string
  children: ReactNode
}

const PageLayout: FC<Props> = ({ title, children }) => {
  return (
    <Flex
      direction="column"
      gap={2}
      pt={2}
      px={{
        base: 2,
        md: 0,
      }}
      overflowY="hidden"
    >
      <Heading>{title}</Heading>
      <Flex direction="column" pt={2} gap={4} overflowY="auto">
        {children}
      </Flex>
    </Flex>
  )
}

export default PageLayout
