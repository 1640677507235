import {
  IProduct,
  IProductUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Product'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ProductForm from './ProductForm'

interface Props {
  product: IProduct
  categories: IProductCategory[]
  onSubmit: (product: IProductUpdateInput) => Promise<void>
}

const EditProductForm: FC<Props> = ({ product, categories, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <ProductForm
      submitButtonText={t('common.actions.update', 'Update')}
      categories={categories}
      onSubmit={onSubmit}
      initialValues={product}
    />
  )
}

export default EditProductForm
