import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { IUser, permissionsByRole } from 'aupiq-pos-shared/src/schemas/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { routes, routesConfig } from '../AppRoute'

interface MenuButtonProps {
  icon: string
  title: string
  linkTo: string
  onClick?: () => void
}

const MenuButton: FC<MenuButtonProps> = ({ icon, title, linkTo, onClick }) => {
  return (
    <Link
      onClick={onClick}
      as={NavLink}
      to={linkTo}
      borderRadius="md"
      textDecoration="none"
      color="gray.600"
      _hover={{ textDecoration: 'none', color: 'black' }}
      _activeLink={{ bgColor: 'blue.100', color: 'black' }}
    >
      <Flex
        direction="column"
        alignItems="center"
        p={1}
        w={{
          base: '65px',
          md: 'auto',
        }}
        h={{
          base: 'auto',
          md: '60px',
        }}
      >
        <Box fontSize="2xl">{icon}</Box>
        <Text fontSize="2xs" textAlign="center" lineHeight="normal">
          {title}
        </Text>
      </Flex>
    </Link>
  )
}

interface Props {
  onLogout: () => void
}

const MainMenu: FC<Props> = ({ onLogout }) => {
  const { t } = useTranslation()

  const { data: user } = useQuery<IUser>({
    queryKey: ['user'],
    enabled: false,
  })

  const userPermissions = user?.role && permissionsByRole[user.role]

  return (
    <Flex
      direction={{
        base: 'row',
        md: 'column',
      }}
      height="full"
    >
      <Flex
        p={1}
        direction={{
          base: 'row',
          md: 'column',
        }}
        gap={2}
        height="full"
        flex={1}
        overflowY="auto"
      >
        {routes
          .filter(route => {
            const requriedPermission = routesConfig[route].requiredPermission
            if (!requriedPermission) {
              return true
            }
            return userPermissions?.[requriedPermission] === true
          })
          .map(route => {
            return (
              <MenuButton
                key={routesConfig[route].id}
                icon={routesConfig[route].icon}
                title={t(
                  `pages.${routesConfig[route].id}`,
                  routesConfig[route].defaultName,
                )}
                linkTo={'/' + routesConfig[route].id}
              />
            )
          })}
      </Flex>

      <Flex
        p={1}
        paddingBlockEnd={0}
        direction="column"
        borderTopWidth={{
          base: 0,
          md: 1,
        }}
        borderInlineStartWidth={{
          base: 1,
          md: 0,
        }}
        borderStyle="solid"
        borderColor="gray.100"
      >
        <MenuButton
          icon="🔒"
          title={t('common.actions.logout', 'Logout')}
          linkTo="/logout"
          onClick={onLogout}
        />
      </Flex>
    </Flex>
  )
}

export default MainMenu
