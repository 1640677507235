import { Grid } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ProductCategoryListComposite: FC<Props> = ({ children }) => {
  return (
    <Grid gap={2} autoRows="auto">
      {children}
    </Grid>
  )
}

export default ProductCategoryListComposite
