import { Text } from '@chakra-ui/react'
import { IBusiness } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import DivButton from '../../shared/components/DivButton'

interface Props {
  business: IBusiness
  onClick?: () => void
}

const BusinessCard: FC<Props> = ({ business, onClick }) => {
  return (
    <DivButton theme="subtle" onClick={onClick}>
      <Text fontWeight="bold">{business.name}</Text>
      <Text>Currency: {business.currency}</Text>
      <Text>Language: {business.language}</Text>
      <Text>ID: {business.id}</Text>
    </DivButton>
  )
}

export default BusinessCard
