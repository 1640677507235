import {
  Currency,
  IBusinessCreateInput,
  Language,
} from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessForm from './BusinessForm'

interface Props {
  onSubmit: (business: IBusinessCreateInput) => Promise<void>
}

const CreateBusinessForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <BusinessForm
      initialValues={{
        name: '',
        language: Language.FR,
        currency: Currency.TND,
      }}
      submitButtonText={t('common.actions.create', 'Create')}
      onSubmit={onSubmit}
    />
  )
}

export default CreateBusinessForm
