import { z } from 'zod'

import { businessScopedModelSchema } from './abstract/BusinessScopedModel'
import { userSchema } from './User'

export const productCategorySchema = businessScopedModelSchema.extend({
  id: z.string().uuid().min(1),
  name: z.string().trim().min(1),
  color: z
    .string()
    .regex(/^#[0-9A-Fa-f]{6}$/)
    .nullish(),
  createdByUserId: userSchema.shape.id,
})

export type IProductCategory = z.infer<typeof productCategorySchema>

export const productCategoryCreateSchema = productCategorySchema.pick({
  name: true,
  color: true,
  businessId: true,
  createdByUserId: true,
})

export type IProductCategoryCreateInput = z.infer<
  typeof productCategoryCreateSchema
>

export const productCategoryCreateRequestSchema =
  productCategoryCreateSchema.pick({
    name: true,
    color: true,
  })

export type IProductCategoryCreateRequest = z.infer<
  typeof productCategoryCreateRequestSchema
>

export const productCategoryUpdateSchema = productCategorySchema
  .pick({
    name: true,
    color: true,
  })
  .partial()

export type IProductCategoryUpdateInput = z.infer<
  typeof productCategoryUpdateSchema
>
