import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import useFetchBusiness from '../../settings/requests/useFetchBusiness'
import useConnectedUser from '../../shared/requests/useConnectedUser'
import { getIconForUserRole } from '../../shared/utils/getIconForUserRole'

const StatusBar: FC = () => {
  const { data: user } = useConnectedUser()
  const { data: business } = useFetchBusiness()

  return (
    <Flex bgColor="gray.100" p={2} gap={2}>
      <Text>{business?.name}</Text>
      <Divider orientation="vertical" />
      {user?.role && <Text>{getIconForUserRole(user.role)}</Text>}
      <Text>{user?.username}</Text>
      <Box flex={1} />
    </Flex>
  )
}

export default StatusBar
