import { Language } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageInNative: Record<Language, string> = {
  [Language.EN]: 'English',
  [Language.FR]: 'Français',
  [Language.AR]: 'العربية',
}

interface Props {
  language: Language
}

const LanguageName: FC<Props> = ({ language }) => {
  const { t } = useTranslation()

  return (
    <>
      {t(`business.attributes.language.${language}`, language)}
      {' - '}
      {LanguageInNative[language]}
    </>
  )
}

export default LanguageName
