import { useQuery } from '@tanstack/react-query'
import { businessSchema } from 'aupiq-pos-shared/src/schemas/Business'
import axios from 'axios'

import { QUERY_STALE_TIME } from '../../../constants'
import useConnectedUser from '../../shared/requests/useConnectedUser'

const useFetchBusiness = () => {
  const { data: user } = useConnectedUser()

  return useQuery({
    staleTime: QUERY_STALE_TIME,
    queryKey: ['businesses', user?.businessId],
    enabled: Boolean(user),
    queryFn: () =>
      axios
        .get(`/api/businesses/${user?.businessId}`)
        .then(res => businessSchema.parse(res.data)),
  })
}

export default useFetchBusiness
