import { AddIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DivButton from '../../shared/components/DivButton'

interface Props {
  onClick: () => void
}

const CreateProductCategoryButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <DivButton
      theme="subtle"
      display="flex"
      alignItems="center"
      fontWeight="semibold"
      minH={12}
      onClick={onClick}
    >
      <Flex gap={4} alignItems="center">
        <AddIcon />
        <Text>{t('product-category.create.action', 'New category')}</Text>
      </Flex>
    </DivButton>
  )
}

export default CreateProductCategoryButton
