import { Grid, GridItem } from '@chakra-ui/react'
import { compareAsc } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import UserCard from '../../shared/components/UserCard'
import PageLayout from '../../shared/layouts/PageLayout'
import useConnectedUser from '../../shared/requests/useConnectedUser'
import useFetchAllUsers from '../requests/useFetchAllUsers'

const AdminManageUsersPage: FC = () => {
  const { t } = useTranslation()

  const { data: users, isLoading } = useFetchAllUsers()
  const { data: connectedUser } = useConnectedUser()

  if (isLoading) {
    return <div>loading...</div>
  }

  return (
    <PageLayout title={t('pages.manage-all-users', 'Users')}>
      <Grid templateColumns="repeat(auto-fill, minmax(240px, 1fr))" gap={4}>
        {users
          ?.toSorted((a, b) => compareAsc(a.created_at, b.created_at))
          .map(user => {
            return (
              <GridItem key={user.id}>
                <UserCard
                  user={user}
                  isCurrentUser={user.id === connectedUser?.id}
                  businessName={user.business.name}
                />
              </GridItem>
            )
          })}
      </Grid>
    </PageLayout>
  )
}

export default AdminManageUsersPage
