import { Box, Skeleton, Text } from '@chakra-ui/react'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC, ReactElement } from 'react'

import ColorBar from './ColorBar'
import DivButton from './DivButton'

const minH = 12

interface Props {
  category: Pick<IProductCategory, 'id' | 'name' | 'color'>
  isActive?: boolean
  onClick: (productId: IProductCategory['id']) => void
  icon?: ReactElement
}

const ProductCategoryCard: FC<Props> & { Loading: FC } = ({
  category,
  isActive,
  icon,
  onClick,
}) => {
  return (
    <DivButton
      theme="subtle"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      p={2}
      paddingInlineStart={3}
      minH={minH}
      borderRadius="md"
      overflow="hidden"
      bgColor={isActive && category.color ? category.color : undefined}
      color={isActive ? 'white' : undefined}
      fontWeight="semibold"
      position="relative"
      onClick={() => onClick?.(category.id)}
      _hover={{
        bgColor: isActive && category.color ? category.color : undefined,
        opacity: 0.8,
      }}
    >
      <ColorBar color={category.color || undefined} />
      <Text>{category.name}</Text>
      {icon && <Box color="gray.400">{icon}</Box>}
    </DivButton>
  )
}

const Loading: FC = () => {
  return <Skeleton minH={minH} />
}

ProductCategoryCard.Loading = Loading

export default ProductCategoryCard
