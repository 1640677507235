import { IOrder, IOrderLine } from 'aupiq-pos-shared/src/schemas/Order'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'

const addProductToOrderLines = (
  orderLines: IOrderLine[],
  product: IProduct,
): IOrderLine[] => {
  const orderLineWithProductExists = orderLines.some(
    orderLine => orderLine.product.id === product.id,
  )

  if (orderLineWithProductExists) {
    return orderLines.map(orderLine => {
      if (orderLine.product.id === product.id) {
        return {
          ...orderLine,
          quantity: orderLine.quantity + 1,
        }
      }
      return orderLine
    })
  }

  return [
    ...orderLines,
    {
      quantity: 1,
      product: {
        id: product.id,
        name: product.name,
        price: product.price,
      },
    },
  ]
}

const addProductToOrder = (order: IOrder, product: IProduct): IOrder => {
  return {
    ...order,
    orderLines: addProductToOrderLines(order.orderLines, product),
  }
}

export default addProductToOrder
