import { IProductCreateRequest } from 'aupiq-pos-shared/src/schemas/Product'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ProductForm from './ProductForm'

interface Props {
  categories: IProductCategory[]
  onSubmit: (product: IProductCreateRequest) => Promise<void>
}

const CreateProductForm: FC<Props> = ({ categories, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <ProductForm
      initialValues={{
        name: '',
        price: 0,
      }}
      submitButtonText={t('common.actions.create', 'Create')}
      categories={categories}
      onSubmit={onSubmit}
    />
  )
}

export default CreateProductForm
