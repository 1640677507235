import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import customTheme from '../../../theme'

interface Props {
  children: React.ReactNode
}

const CustomChakraProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()

  const [dir, setDir] = useState(i18n.dir())

  i18n.on('languageChanged', (lng: string) => {
    setDir(i18n.dir(lng))
  })

  const customThemeWithDir = extendTheme(customTheme, { direction: dir })

  return <ChakraProvider theme={customThemeWithDir}>{children}</ChakraProvider>
}

export default CustomChakraProvider
