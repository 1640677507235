import { Currency } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  currency: Currency
}

const CurrencyName: FC<Props> = ({ currency }) => {
  const { t } = useTranslation()

  return (
    <>
      {t(`business.attributes.currency.${currency}.code`, currency)} -{' '}
      {t(`business.attributes.currency.${currency}.name`, currency)} -{' '}
      {t(`business.attributes.currency.${currency}.symbol`, currency)}
    </>
  )
}

export default CurrencyName
