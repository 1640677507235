import Digit from './Digit'

const inputDigit = (newDigit: Digit, digits: Digit[]): Digit[] => {
  // don't allow leading zeros
  if (digits.length === 0 && newDigit === 0) {
    return digits
  }
  return [...digits, newDigit]
}

export default inputDigit
