import { Flex, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useDoubleTap } from 'use-double-tap'
import DivButton from '../../shared/components/DivButton'

const PaymentMethodButton = ({
  icon,
  name,
  isLoading,
  onClick,
  onDoubleClick,
}: {
  icon: string
  name: string
  isLoading: boolean
  onClick: () => void
  onDoubleClick: () => void
}) => {
  const { t } = useTranslation()
  const bind = useDoubleTap(
    () => {
      if (isLoading) {
        return
      }
      onDoubleClick()
    },
    undefined,
    {
      onSingleTap: () => {
        if (isLoading) {
          return
        }
        onClick()
      },
    },
  )
  return (
    <DivButton display="flex" alignItems="center" p={2} gap={2} {...bind}>
      {isLoading && (
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems="center"
          justifyContent="center"
          backgroundColor="whiteAlpha.700"
          data-testid="PaymentMehtodButton-LoadingSpinner"
        >
          <Spinner color="gray.300" />
        </Flex>
      )}
      <Text as="span" fontSize="2xl">
        {icon}
      </Text>{' '}
      {t(`payment.methods.${name}`)}
    </DivButton>
  )
}

export default PaymentMethodButton
