import { z } from 'zod'

import { businessScopedModelSchema } from './abstract/BusinessScopedModel'
import { userSchema } from './User'

export const productSchema = businessScopedModelSchema.extend({
  name: z.string().trim().min(1),
  price: z.number().int().min(0),
  categoryId: z.string().uuid().min(1).nullish(),
  createdByUserId: userSchema.shape.id,
  isActive: z.boolean(),
  // TODO : add this attribute
  // allowCustomPrice: z.boolean().nullish().default(false),
})

export type IProduct = z.infer<typeof productSchema>

export const productCreateSchema = productSchema.pick({
  name: true,
  price: true,
  categoryId: true,
  businessId: true,
  createdByUserId: true,
})

export type IProductCreateInput = z.infer<typeof productCreateSchema>

export const productCreateRequestSchema = productCreateSchema.pick({
  name: true,
  price: true,
  categoryId: true,
})

export type IProductCreateRequest = z.infer<typeof productCreateRequestSchema>

export const productUpdateSchema = productSchema
  .pick({
    name: true,
    price: true,
    categoryId: true,
    isActive: true,
  })
  .partial()

export type IProductUpdateInput = z.infer<typeof productUpdateSchema>
