import { z } from 'zod'

import { baseModelSchema } from './abstract/BaseModel'
import { businessScopedModelSchema } from './abstract/BusinessScopedModel'
import { businessSchema } from './Business'

export enum Permission {
  ManageProductsAndCategories = 'ManageProductsAndCategories',
  ManageSettings = 'ManageSettings',
  ManageUsers = 'ManageUsers',
  DeleteOrders = 'DeleteOrders',
  ManageBusinesses = 'ManageBusinesses',
  ManageAllUsers = 'ManageAllUsers',
  ManageSalesHistory = 'ManageSalesHistory',
}

export enum UserRole {
  Admin = 'Admin',
  Manager = 'Manager',
  Cashier = 'Cashier',
}

export const permissionsByRole: Record<
  UserRole,
  Record<Permission, boolean>
> = {
  [UserRole.Admin]: {
    [Permission.ManageProductsAndCategories]: true,
    [Permission.ManageSettings]: true,
    [Permission.ManageUsers]: true,
    [Permission.DeleteOrders]: true,
    [Permission.ManageBusinesses]: true,
    [Permission.ManageAllUsers]: true,
    [Permission.ManageSalesHistory]: true,
  },
  [UserRole.Manager]: {
    [Permission.ManageProductsAndCategories]: true,
    [Permission.ManageSettings]: true,
    [Permission.ManageUsers]: true,
    [Permission.DeleteOrders]: true,
    [Permission.ManageBusinesses]: false,
    [Permission.ManageAllUsers]: false,
    [Permission.ManageSalesHistory]: true,
  },
  [UserRole.Cashier]: {
    [Permission.ManageProductsAndCategories]: false,
    [Permission.ManageSettings]: false,
    [Permission.ManageUsers]: false,
    [Permission.DeleteOrders]: false,
    [Permission.ManageBusinesses]: false,
    [Permission.ManageAllUsers]: false,
    [Permission.ManageSalesHistory]: false,
  },
}

export const userSchema = businessScopedModelSchema.extend({
  username: z.string().trim().toLowerCase().min(1),
  password: z.string().min(1),
  role: z.nativeEnum(UserRole),
  createdByUserId: baseModelSchema.shape.id,
  isActive: z.boolean(),
})

export type IUser = z.infer<typeof userSchema>

export const userCreeateSchema = userSchema.pick({
  username: true,
  password: true,
  role: true,
  businessId: true,
  createdByUserId: true,
})

export type IUserCreateInput = z.infer<typeof userCreeateSchema>

export const userCreateRequestSchema = userCreeateSchema.pick({
  username: true,
  password: true,
  role: true,
})

export type IUserCreateRequest = z.infer<typeof userCreateRequestSchema>

export const userUpdateSchema = userSchema
  .pick({
    password: true,
  })
  .partial()

export type IUserUpdateInput = z.infer<typeof userUpdateSchema>

export const userWithoutPasswordSchema = userSchema
  .omit({ password: true })
  .extend({
    password: z.null(),
  })

export type IUserWithoutPassword = z.infer<typeof userWithoutPasswordSchema>

export const userWithBusinessSchema = userWithoutPasswordSchema.extend({
  business: businessSchema,
})

export type IUserWithBusiness = z.infer<typeof userWithBusinessSchema>

export const userContextSchema = userSchema.pick({
  id: true,
  role: true,
  username: true,
  businessId: true,
})

export type IUserContext = z.infer<typeof userContextSchema>

export const userLoginSchema = userSchema.pick({
  username: true,
  password: true,
})

export type IUserLogin = z.infer<typeof userLoginSchema>

export const UserUtils = {
  canCreateUserWithRole: (
    userRole: UserRole,
    newUserRole: UserRole,
  ): boolean => {
    switch (userRole) {
      // cashier cannot create users
      case UserRole.Cashier:
        return false
      // admin can create any user
      case UserRole.Admin:
        return true
      // manager can only create cashiers
      case UserRole.Manager:
        return newUserRole === UserRole.Cashier
    }
  },
}
