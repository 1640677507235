import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepProps,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
  VStack,
} from '@chakra-ui/react'
import { FC } from 'react'
import logo from '../assets/logo.svg'
import SignupForm from '../components/SignupForm'

const steps: StepProps[] = [{ title: 'Business' }, { title: 'Manager' }]

const SignupPage: FC = () => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })
  return (
    <Flex
      maxW="md"
      mx="auto"
      direction="column"
      alignItems="center"
      p={4}
      gap={8}
      overflowY="auto"
    >
      <Heading pb={4}>
        <img width={200} src={logo} />
      </Heading>
      <VStack pt={4} spacing={8} overflowY="auto">
        <Box w="full" px={8}>
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index} onClick={() => setActiveStep(index)}>
                <Flex direction="column" alignItems="center" gap={2}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <StepTitle>{step.title}</StepTitle>
                </Flex>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 && (
          <Flex direction="column" gap={8}>
            <Flex
              alignItems="center"
              gap={4}
              bgColor="gray.100"
              p={4}
              borderRadius="lg"
            >
              <InfoIcon color="blue.500" boxSize={8} />
              <Text>Indiquer quelques informations sur votre commerce</Text>
            </Flex>
            <Box w="full">
              <SignupForm
                onSubmit={config => {
                  console.log(config)
                  return Promise.resolve()
                }}
              />
            </Box>
          </Flex>
        )}
      </VStack>
    </Flex>
  )
}

export default SignupPage
