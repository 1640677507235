import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddIcon } from '@chakra-ui/icons'
import {
  IBusiness,
  IBusinessCreateInput,
  IBusinessUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Business'
import { useHistory } from 'react-router-dom'
import AppRoute from '../../app/AppRoute'
import useDeleteBusiness from '../../settings/requests/useDeleteBusiness'
import ActionMenu from '../../shared/components/ActionMenu'
import DivButton from '../../shared/components/DivButton'
import Modal from '../../shared/components/Modal'
import PageLayout from '../../shared/layouts/PageLayout'
import BusinessCard from '../components/BusinessCard'
import CreateBusinessForm from '../components/CreateBusinessForm'
import EditBusinessForm from '../components/EditBusinessForm'
import useCreateBusiness from '../requests/useCreateBusiness'
import useFetchBusinesses from '../requests/useFetchBusinesses'
import useUpdateBusiness from '../requests/useUpdateBusiness'

const ManageBusinessesPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { data: businesses, isLoading } = useFetchBusinesses()

  const createBusiness = useCreateBusiness()
  const updateBusiness = useUpdateBusiness()
  const deleteBusiness = useDeleteBusiness()

  const {
    isOpen: isCreateBusinessModalOpen,
    onOpen: onOpenCreateUserModal,
    onClose: onCloseCreateBusinessModal,
  } = useDisclosure()

  const [businessWithActiveDialog, setBusinessWithActiveDialog] =
    useState<null | {
      business: IBusiness
      dialog: 'menu' | 'edit' | 'confirm-delete'
    }>(null)

  const cancelDeleteBusinessButtonRef = useRef(null)

  const openBusinessMenu = (business: IBusiness) => {
    setBusinessWithActiveDialog({ business, dialog: 'menu' })
  }

  const openBusinessToEdit = (business: IBusiness) => {
    setBusinessWithActiveDialog({ business, dialog: 'edit' })
  }

  const openBusinessToDelete = (business: IBusiness) => {
    setBusinessWithActiveDialog({ business, dialog: 'confirm-delete' })
  }

  const closeBusinessDialogs = () => {
    setBusinessWithActiveDialog(null)
  }

  const onCreateBusiness = async (business: IBusinessCreateInput) => {
    await createBusiness.mutateAsync(business)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!businesses) {
    return <div>No data!</div>
  }

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={businessWithActiveDialog?.dialog === 'confirm-delete'}
        leastDestructiveRef={cancelDeleteBusinessButtonRef}
        onClose={closeBusinessDialogs}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('business.delete.title', 'Delete business')}
              {' - '}
              {businessWithActiveDialog?.business.name}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                'common.delete.confirm-message',
                "Are you sure? You can't undo this action afterwards",
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDeleteBusinessButtonRef}
                onClick={closeBusinessDialogs}
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (!businessWithActiveDialog?.business) {
                    return
                  }
                  try {
                    await deleteBusiness.mutateAsync(
                      businessWithActiveDialog.business.id,
                    )
                    closeBusinessDialogs()
                  } catch (err) {
                    /* empty */
                  }
                }}
                ml={3}
              >
                {t('common.actions.delete', 'Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        title={t('business.create.title', 'Create new business')}
        isOpen={isCreateBusinessModalOpen}
        onClose={onCloseCreateBusinessModal}
      >
        <CreateBusinessForm
          onSubmit={async (business: IBusinessCreateInput) => {
            try {
              await onCreateBusiness(business)
              onCloseCreateBusinessModal()
            } catch (err) {
              /* empty */
            }
          }}
        />
      </Modal>
      <ActionMenu
        isOpen={businessWithActiveDialog?.dialog === 'menu'}
        onClose={closeBusinessDialogs}
        title={businessWithActiveDialog?.business.name || ''}
        items={
          !businessWithActiveDialog?.business
            ? []
            : [
                {
                  title: t('business.manage-users.title', 'Manage users'),
                  onClick: () => {
                    history.push(
                      `${AppRoute.ManageBusinesses}/${businessWithActiveDialog.business.id}`,
                    )
                  },
                },
                {
                  title: t('common.actions.edit', 'Edit'),
                  onClick: () =>
                    openBusinessToEdit(businessWithActiveDialog.business),
                },
                'divider',
                {
                  title: t('common.actions.delete', 'Delete'),
                  isDestructive: true,
                  onClick: () =>
                    openBusinessToDelete(businessWithActiveDialog.business),
                },
              ]
        }
      />
      <Modal
        isOpen={businessWithActiveDialog?.dialog === 'edit'}
        onClose={closeBusinessDialogs}
        title={
          t('business.edit.title', 'Edit business') +
          ' - ' +
          businessWithActiveDialog?.business.name
        }
      >
        {businessWithActiveDialog?.business && (
          <EditBusinessForm
            business={businessWithActiveDialog.business}
            onSubmit={async (business: IBusinessUpdateInput) => {
              try {
                await updateBusiness.mutateAsync({
                  id: businessWithActiveDialog.business.id,
                  business,
                })
                closeBusinessDialogs()
              } catch (err) {
                /* empty */
              }
            }}
          />
        )}
      </Modal>
      <PageLayout title={t('pages.manage-businesses', 'Businesses')}>
        <DivButton
          theme="subtle"
          display="flex"
          gap={4}
          alignItems="center"
          onClick={onOpenCreateUserModal}
        >
          <AddIcon />
          <Text fontWeight="bold">
            {t('business.create.action', 'New business')}
          </Text>
        </DivButton>
        {businesses.map(business => (
          <BusinessCard
            key={business.id}
            business={business}
            onClick={() => openBusinessMenu(business)}
          />
        ))}
      </PageLayout>
    </>
  )
}

export default ManageBusinessesPage
