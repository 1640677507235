import { GridItem } from '@chakra-ui/react'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { FC } from 'react'

import ProductCard from '../../../../shared/components/ProductCard'
import ProductListComposite from '../../../../shared/components/ProductListComposite'
import ProductListLoading from '../../../../shared/components/ProductListLoading'

interface Props {
  productsWithColors: { product: IProduct; color?: string }[]
  onClickProduct: (productId: IProduct['id']) => void
}

const ProductList: FC<Props> & { Loading: FC } = ({
  productsWithColors: productsWithColors,
  onClickProduct,
}) => {
  return (
    <ProductListComposite>
      {productsWithColors.map(({ product, color }) => (
        <GridItem key={product.id} display="grid">
          <ProductCard
            product={product}
            color={color}
            onClick={onClickProduct}
          />
        </GridItem>
      ))}
    </ProductListComposite>
  )
}

const Loading = ProductListLoading

ProductList.Loading = Loading

export default ProductList
