import {
  IProductCategory,
  IProductCategoryUpdateInput,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ProductCategoryForm from './ProductCategoryForm'

interface Props {
  productCategory: IProductCategory
  onSubmit: (productCategory: IProductCategoryUpdateInput) => Promise<void>
}

const EditProductCategoryForm: FC<Props> = ({ productCategory, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <ProductCategoryForm
      submitButtonText={t('common.actions.update', 'Update')}
      initialValues={productCategory}
      onSubmit={onSubmit}
    />
  )
}

export default EditProductCategoryForm
