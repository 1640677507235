import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IUserLogin,
  IUserWithoutPassword,
} from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import AppRoute from '../AppRoute'

const useLogin = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const toast = useToast()
  const { t } = useTranslation()

  return useMutation<{ token: string }, DefaultError, IUserLogin>({
    mutationFn: async userLogin => {
      return axios.post('/api/auth/login', userLogin).then(res => res.data)
    },
    onSuccess: ({ token }) => {
      const user = jwtDecode<IUserWithoutPassword>(token)
      localStorage.setItem('authToken', token)
      queryClient.setQueryData(['user'], user)
      history.push(AppRoute.TakeOrders)
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('user.login.error', 'while trying to sign in'),
      })
    },
  })
}

export default useLogin
