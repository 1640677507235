import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { IUserLogin } from 'aupiq-pos-shared/src/schemas/User'
import { FC } from 'react'
import logo from '../assets/logo.svg'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppRoute from '../AppRoute'
import LoginForm from '../components/LoginForm'
import useLogin from '../requests/useLogin'

const LoginPage: FC = () => {
  const { t } = useTranslation()
  const login = useLogin()

  const onSubmit = async (userLogin: IUserLogin) => {
    await login.mutateAsync(userLogin)
  }
  return (
    <Flex
      maxW="md"
      mx="auto"
      direction="column"
      alignItems="center"
      p={4}
      gap={8}
      overflowY="auto"
    >
      <Heading size="lg" pb={4}>
        <img width={200} src={logo} />
      </Heading>
      <Box w="full">
        <LoginForm onSubmit={onSubmit} />
      </Box>
      <VStack spacing={4}>
        <Text fontSize="lg" color="gray.500">
          {t(
            'signup.explainer',
            'No account yet? Setup your business in minutes',
          )}
        </Text>

        <Link to={'/' + AppRoute.Signup}>
          <Button
            w="40"
            type="submit"
            size="lg"
            color="white"
            bgGradient="linear(to-l, #2EBF91, #8360C3)"
            _hover={{
              opacity: 0.9,
            }}
          >
            {t('signup.action', 'Sign up')}
          </Button>
        </Link>
      </VStack>
    </Flex>
  )
}

export default LoginPage
