import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useDeleteProductCateogry = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: user } = useConnectedUser()

  return useMutation<void, DefaultError, string>({
    mutationFn: async productCategoryId => {
      return axios.delete(
        `/api/businesses/${user?.businessId}/categories/${productCategoryId}`,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'categories'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t(
          'product.errors.deletion',
          'while deleting the product category',
        ),
      })
    },
  })
}

export default useDeleteProductCateogry
