import { Language } from 'aupiq-pos-shared/src/schemas/Business'

const formatTime = (date: Date, language: Language): string => {
  return Intl.DateTimeFormat(language, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date)
}

export default formatTime
