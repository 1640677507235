import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import CustomChakraProvider from './features/app/components/CustomChakraProvider.tsx'
import i18n from './i18n/i18n.ts'
import sentryConfig from './sentryConfig.ts'

Sentry.init(sentryConfig)

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <CustomChakraProvider>
          <Router>
            <App />
          </Router>
        </CustomChakraProvider>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>,
)
