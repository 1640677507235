import { useQuery } from '@tanstack/react-query'
import { userWithBusinessSchema } from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { z } from 'zod'

const useFetchAllUsers = () => {
  return useQuery({
    queryKey: ['admin', 'users'],
    queryFn: () => {
      return axios
        .get(`/api/admin/users`)
        .then(res => z.array(userWithBusinessSchema).parse(res.data))
    },
  })
}

export default useFetchAllUsers
