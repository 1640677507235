import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { IUserWithBusiness } from 'aupiq-pos-shared/src/schemas/User'
import useConnectedUser from '../../shared/requests/useConnectedUser'

const useDeactivateUser = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: connectedUser } = useConnectedUser()

  return useMutation<IUserWithBusiness, DefaultError, string>({
    mutationFn: async userId => {
      return axios.post(
        `/api/businesses/${connectedUser?.businessId}/users/${userId}/deactivate`,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', connectedUser?.businessId, 'users'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('user.deactivate.error', 'while suspending the account'),
      })
    },
  })
}

export default useDeactivateUser
