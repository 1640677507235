import { IUserCreateRequest, UserRole } from 'aupiq-pos-shared/src/schemas/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import UserForm from './UserForm'

interface Props {
  onSubmit: (user: IUserCreateRequest) => Promise<void>
}

const CreateUserForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <UserForm
      initialValues={{
        username: '',
        password: '',
        role: UserRole.Cashier,
      }}
      submitButtonText={t('common.actions.create', 'Create')}
      onSubmit={onSubmit}
    />
  )
}

export default CreateUserForm
