import { GridItem } from '@chakra-ui/react'
import { IOrderLine } from 'aupiq-pos-shared/src/schemas/Order'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { FC } from 'react'

import CurrencyAmount from '../../../../shared/components/currency/CurrencyAmount'
import DivButton from '../../../../shared/components/DivButton'

interface Props {
  orderLine: IOrderLine
  onClick: (orderLineProductId: IProduct['id']) => void
}

const OrderLine: FC<Props> = ({
  orderLine: { product, quantity },
  onClick,
}) => {
  return (
    <DivButton
      theme="subtle"
      display="grid"
      gridTemplateColumns="1fr auto auto"
      gap={4}
      onClick={() => onClick(product.id)}
    >
      <GridItem>{product.name}</GridItem>
      <GridItem>
        <CurrencyAmount amount={product.price} />
      </GridItem>
      <GridItem>x{quantity}</GridItem>
    </DivButton>
  )
}

export default OrderLine
