import { AddIcon } from '@chakra-ui/icons'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DivButton from '../../shared/components/DivButton'

interface Props {
  onClick: () => void
}

const CreateUserButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <DivButton
      display="grid"
      gridTemplateColumns="auto auto 1fr"
      gap="2"
      theme="subtle"
      onClick={onClick}
    >
      <Flex direction="column" alignItems="center">
        <Text fontSize="4xl">👤</Text>
      </Flex>
      <Divider orientation="vertical" />
      <Flex gap={4} alignItems="center">
        <AddIcon />
        <Text fontWeight="semibold">
          {t('user.create.action', 'New member')}
        </Text>
      </Flex>
    </DivButton>
  )
}

export default CreateUserButton
