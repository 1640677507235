import { Box, Flex, Text } from '@chakra-ui/react'
import { Language } from 'aupiq-pos-shared/src/schemas/Business'
import { IOrder } from 'aupiq-pos-shared/src/schemas/Order'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import formatAdaptiveDateTime from '../../../../../services/format/date-time/formatAdaptiveDateTime'
import CurrencyAmount from '../../../../shared/components/currency/CurrencyAmount'
import DivButton from '../../../../shared/components/DivButton'
import OrderLinesList from '../../../../shared/components/OrderLinesList'
import OrderStatusTag from '../../../../shared/components/OrderStatusTag'
import getOrderTotal from '../../../../shared/utils/getOrderTotal'

interface Props {
  order: IOrder
  onClick: (orderId: string) => void
}

const OrderPreviewCard: FC<Props> = ({ order, onClick }) => {
  const { i18n } = useTranslation()
  const itemsCount = order.orderLines.reduce(
    (count, orderLine) => count + orderLine.quantity,
    0,
  )

  const orderTotal = getOrderTotal(order)
  const amountStillToPay = orderTotal - order.paidAmount

  return (
    <DivButton
      onClick={() => onClick(order.id)}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box p={0}>
        <Flex alignItems="center" gap={2}>
          <Text fontSize="md" fontWeight="semibold">
            {formatAdaptiveDateTime(
              order.created_at,
              i18n.language as Language,
            )}
          </Text>
          <Flex flex={1} justifyContent="end">
            <OrderStatusTag status={order.status} />
          </Flex>
          {itemsCount} 🏷️
        </Flex>
      </Box>
      <Box>
        <OrderLinesList orderLines={order.orderLines} />
      </Box>
      <Flex gap={2}>
        <Text fontWeight="semibold">
          <CurrencyAmount amount={amountStillToPay} />
        </Text>
        {order.paidAmount > 0 && (
          <Flex
            data-testid="OrderDetailsPanel-OrderTotal"
            justifyContent="end"
            textDecoration="line-through"
            textDecorationColor="gray.400"
          >
            <CurrencyAmount amount={orderTotal} />
          </Flex>
        )}
      </Flex>
    </DivButton>
  )
}

export default OrderPreviewCard
