import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IUserCreateRequest,
  IUserWithoutPassword,
} from 'aupiq-pos-shared/src/schemas/User'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const useCreateUserForBusiness = (businessId?: string) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation<IUserWithoutPassword, DefaultError, IUserCreateRequest>({
    mutationFn: async user => {
      return axios
        .post(`/api/businesses/${businessId}/users`, user)
        .then(res => res.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', businessId, 'users'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('user.errors.saving', 'while saving the member'),
      })
    },
  })
}

export default useCreateUserForBusiness
