import { Language } from 'aupiq-pos-shared/src/schemas/Business'

const formatAdaptiveDateTime = (
  createdAt: Date,
  language: Language,
): string => {
  const now = new Date()
  const isToday = createdAt.toDateString() === now.toDateString()
  const isThisYear = createdAt.getFullYear() === now.getFullYear()

  const dateOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    day: isToday ? undefined : '2-digit',
    month: isToday ? undefined : '2-digit',
    year: isThisYear ? undefined : 'numeric',
  }

  return new Intl.DateTimeFormat(language, dateOptions).format(createdAt)
}

export default formatAdaptiveDateTime
