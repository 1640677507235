import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { FC, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import ColorBar from './ColorBar'
import CurrencyAmount from './currency/CurrencyAmount'
import DivButton from './DivButton'

const minH = 20

interface Props {
  product: Pick<IProduct, 'id' | 'name' | 'price' | 'isActive'>
  color?: string
  onClick: (productId: IProduct['id']) => void
  icon?: ReactElement
}

const ProductCard: FC<Props> & { Loading: FC } = ({
  product,
  color,
  icon,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <DivButton
      theme="subtle"
      display="grid"
      minH={minH}
      overflow="hidden"
      borderRadius="md"
      fontWeight="semibold"
      textAlign="start"
      position="relative"
      p={2}
      paddingInlineStart={3}
      onClick={() => onClick(product.id)}
    >
      <ColorBar color={color} />
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Flex
          flexDirection="column"
          textDecorationColor="red.400"
          textDecorationLine={product.isActive ? undefined : 'line-through'}
        >
          <Box flex="1">
            <Text>{product.name}</Text>
            {!product.isActive && (
              <Text fontWeight="normal">
                ({t('product.availability.isInactive', 'Not for sale')})
              </Text>
            )}
          </Box>
          <Text>
            <CurrencyAmount amount={product.price} />
          </Text>
        </Flex>
        {icon && <Box color="gray.400">{icon}</Box>}
      </Box>
    </DivButton>
  )
}

const Loading: FC = () => {
  return <Skeleton minH={minH} />
}

ProductCard.Loading = Loading

export default ProductCard
