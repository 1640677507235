import { Currency } from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  currency: Currency
}

const CurrencySymbol: FC<Props> = ({ currency }) => {
  const { t } = useTranslation()

  return t(`business.attributes.currency.${currency}.symbol`, currency)
}

export default CurrencySymbol
