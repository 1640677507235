import { Box, Flex, Grid, Heading, Skeleton, Text } from '@chakra-ui/react'
import {
  IOrder,
  OrderCancelingReason,
} from 'aupiq-pos-shared/src/schemas/Order'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { compareDesc } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import HintText from '../../../shared/components/text/HintText'

import { AddIcon } from '@chakra-ui/icons'
import DivButton from '../../../shared/components/DivButton'
import OrderDetailsPanel from './components/OrderDetailsPanel'
import OrderPreviewCard from './components/OrderPreviewCard'

interface Props {
  orders: IOrder[]
  /**
   * we either have :
   * - no selection: selectedItems = undefined
   * - an order selected with no orderLine selected: selectedItems = {orderId: 'order-id'}
   * - an order selected with an ordeLined selected: selectedItems = {orderId: 'order-id, orderLineProductId: 'some-id'}
   *
   * the case were we have an orderLine selected without and order is disallowed thanks to this approach
   */
  selectedOrderDetails?: {
    orderId: IOrder['id']
    orderLineProductId?: IProduct['id']
  }
  onClickCreateOrder: () => void
  onClickOrder: (orderId: string) => void
  onCancelOrder: (cancelingReason: OrderCancelingReason) => void
  onUpdateProductQuantity: (quantity: number) => void
  onOpenPaymentPanel: () => void
  isPaymentPanelOpen: boolean
  onClickOrderLine: (orderLineProductId: string) => void
  onCloseOrder: () => void
}

const TakeOrdersSidebar: FC<Props> & { Loading: FC } = ({
  orders,
  selectedOrderDetails,
  onClickCreateOrder,
  onClickOrder,
  onUpdateProductQuantity,
  onCancelOrder,
  onOpenPaymentPanel,
  onClickOrderLine,
  onCloseOrder,
}) => {
  const { t } = useTranslation()

  const selectedOrder =
    selectedOrderDetails &&
    orders.find(order => order.id === selectedOrderDetails.orderId)

  if (!selectedOrder) {
    return (
      <Flex
        direction="column"
        gap={2}
        data-testid="TakeOrdersSidebar-OrderList"
        overflowY="hidden"
      >
        <Box px={2} py={{ base: 1, md: 2 }}>
          <Heading
            textTransform="capitalize"
            color="gray.400"
            fontWeight="medium"
          >
            {t('common.orders', 'Orders')}
          </Heading>
        </Box>
        <Box px={2}>
          <DivButton
            display="flex"
            fontWeight="semibold"
            gap={4}
            alignItems="center"
            onClick={onClickCreateOrder}
          >
            <AddIcon />
            <Text>{t('order.create.action', 'New order')}</Text>
          </DivButton>
        </Box>
        {orders.length ? (
          <Flex direction="column" p={2} gap={2} overflowY="auto">
            {orders
              .slice()
              .sort((a, b) => compareDesc(a.created_at!, b.created_at!))
              .map(order => (
                <OrderPreviewCard
                  key={order.id}
                  order={order}
                  onClick={() => onClickOrder(order.id)}
                />
              ))}
          </Flex>
        ) : (
          <Flex flex="1" p={2}>
            <HintText>
              {t(
                'order.create.empty-state-hint',
                'Click on "➕ $t(order.create.action)" or any product to start a new order',
              )}
            </HintText>
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <Grid templateRows="1fr auto" p={2} overflowY="hidden">
      {selectedOrder && (
        <>
          <OrderDetailsPanel
            order={selectedOrder}
            selectedOrderLineProductId={selectedOrderDetails.orderLineProductId}
            onUpdateProductQuantity={onUpdateProductQuantity}
            onClose={onCloseOrder}
            onCancelOrder={onCancelOrder}
            onOpenPaymentPanel={onOpenPaymentPanel}
            onClickOrderLine={onClickOrderLine}
          />
        </>
      )}
    </Grid>
  )
}

TakeOrdersSidebar.Loading = () => {
  return <Skeleton />
}

export default TakeOrdersSidebar
