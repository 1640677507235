import { ThemeOverride } from '@chakra-ui/react'

const customTheme: ThemeOverride = {
  direction: 'rtl',
  components: {
    Heading: {
      defaultProps: {
        size: 'sm',
      },
    },
    Card: {
      defaultProps: {
        size: 'sm',
      },
    },
  },
}

export default customTheme
