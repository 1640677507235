import { EditIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IProduct,
  IProductCreateRequest,
  IProductUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Product'
import {
  IProductCategory,
  IProductCategoryCreateRequest,
  IProductCategoryUpdateInput,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ActionMenu from '../../../shared/components/ActionMenu'
import Modal from '../../../shared/components/Modal'
import PageLink from '../../../shared/components/PageLink'
import ProductCard from '../../../shared/components/ProductCard'
import ProductCategoryCard from '../../../shared/components/ProductCategoryCard'
import ProductCategoryListComposite from '../../../shared/components/ProductCategoryListComposite'
import ProductListComposite from '../../../shared/components/ProductListComposite'
import HintText from '../../../shared/components/text/HintText'
import ProductsAndCategoriesLayout from '../../../shared/layouts/ProductsAndCategoriesLayout'
import getProductsSortedByCategoryWithColors from '../../../shared/utils/getProductsSortedByCategoryWithColors'
import CreateProductButton from '../../components/CreateProductButton'
import CreateProductCategoryButton from '../../components/CreateProductCategoryButton'
import CreateProductCategoryForm from '../../components/CreateProductCategoryForm'
import CreateProductForm from '../../components/CreateProductForm'
import EditProductCategoryForm from '../../components/EditProductCategoryForm'
import EditProductForm from '../../components/EditProductForm'

interface Props {
  products: IProduct[]
  categories: IProductCategory[]
  onCreateProductCategory: (
    categoryInput: IProductCategoryCreateRequest,
  ) => void
  onCreateProduct: (productInput: IProductCreateRequest) => Promise<void>
  onUpdateProduct: (id: string, product: IProductUpdateInput) => Promise<void>
  onUpdateProductCategory: (
    id: string,
    productCateogry: IProductCategoryUpdateInput,
  ) => Promise<void>
  onDeleteProduct: (productId: string) => Promise<void>
  onDeleteProductCategory: (productCategoryId: string) => Promise<void>
}

const ManageProductsView: FC<Props> & { Loading: FC; Error: FC } = ({
  categories,
  products,
  onCreateProductCategory,
  onCreateProduct,
  onUpdateProductCategory,
  onUpdateProduct,
  onDeleteProduct,
  onDeleteProductCategory,
}) => {
  const { t } = useTranslation()

  const productsSortedByCategoryWithColors = useMemo(() => {
    return getProductsSortedByCategoryWithColors(products, categories)
  }, [products, categories])

  const {
    isOpen: isCreateProductCategoryModalOpen,
    onOpen: onOpenCreateProductCategoryModal,
    onClose: onCloseCreateProductCategoryModal,
  } = useDisclosure()

  const {
    isOpen: isCreateProductModalOpen,
    onOpen: onOpenCreateProductModal,
    onClose: onCloseCreateProductModal,
  } = useDisclosure()

  const [productWithActiveDialog, setProductWithActiveDialog] =
    useState<null | {
      product: IProduct
      dialog: 'menu' | 'edit' | 'confirm-delete'
    }>(null)

  const [categoryWithActiveDialog, setCategoryWithActiveDialog] =
    useState<null | {
      category: IProductCategory
      dialog: 'menu' | 'edit' | 'confirm-delete'
    }>(null)

  const cancelDeleteProductButtonRef = useRef(null)

  const cancelDeleteProductCategoryButtonRef = useRef(null)

  const openProductMenu = (product: IProduct) => {
    setProductWithActiveDialog({ product, dialog: 'menu' })
  }

  const openProductToEdit = (productId: string) => {
    const product = products.find(p => p.id === productId)
    if (!product) {
      return
    }

    setProductWithActiveDialog({ product, dialog: 'edit' })
  }

  const openProductToDelete = (productId: string) => {
    const product = products.find(p => p.id === productId)
    if (!product) {
      return
    }

    setProductWithActiveDialog({ product, dialog: 'confirm-delete' })
  }

  const closeProductDialogs = () => {
    setProductWithActiveDialog(null)
  }

  const openCategoryMenu = (category: IProductCategory) => {
    setCategoryWithActiveDialog({ category, dialog: 'menu' })
  }

  const openProductCategoryToEdit = (productCategoryId: string) => {
    const category = categories.find(p => p.id === productCategoryId)
    if (!category) {
      return
    }

    setCategoryWithActiveDialog({ category, dialog: 'edit' })
  }

  const openProductCategoryToDelete = (productCategoryId: string) => {
    const category = categories.find(p => p.id === productCategoryId)
    if (!category) {
      return
    }

    setCategoryWithActiveDialog({ category, dialog: 'confirm-delete' })
  }

  const closeCategoryDialogs = () => {
    setCategoryWithActiveDialog(null)
  }

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={productWithActiveDialog?.dialog === 'confirm-delete'}
        leastDestructiveRef={cancelDeleteProductButtonRef}
        onClose={closeProductDialogs}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('product.delete.title', 'Delete Product')}
              {' - '}
              {productWithActiveDialog?.product.name}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                'common.delete.confirm-message',
                "Are you sure? You can't undo this action afterwards",
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDeleteProductButtonRef}
                onClick={closeProductDialogs}
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (!productWithActiveDialog?.product) {
                    return
                  }
                  try {
                    await onDeleteProduct(productWithActiveDialog.product.id)
                    closeProductDialogs()
                  } catch (err) {
                    /* empty */
                  }
                }}
                ml={3}
              >
                {t('common.actions.delete', 'Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isCentered
        isOpen={categoryWithActiveDialog?.dialog === 'confirm-delete'}
        leastDestructiveRef={cancelDeleteProductCategoryButtonRef}
        onClose={closeCategoryDialogs}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('product-category.delete.title', 'Delete product category')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                'common.delete.confirm-message',
                "Are you sure? You can't undo this action afterwards",
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDeleteProductCategoryButtonRef}
                onClick={closeCategoryDialogs}
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (!categoryWithActiveDialog?.category) {
                    return
                  }
                  try {
                    await onDeleteProductCategory(
                      categoryWithActiveDialog.category.id,
                    )
                    closeCategoryDialogs()
                  } catch (err) {
                    /* empty */
                  }
                }}
                ml={3}
              >
                {t('common.actions.delete', 'Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isCreateProductCategoryModalOpen}
        onClose={onCloseCreateProductCategoryModal}
        title={t('product-category.create.title', 'Create new category')}
      >
        <CreateProductCategoryForm
          onSubmit={async (productCategory: IProductCategoryCreateRequest) => {
            try {
              await onCreateProductCategory(productCategory)
              onCloseCreateProductCategoryModal()
            } catch (err) {
              /* empty */
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={categoryWithActiveDialog?.dialog === 'edit'}
        onClose={closeCategoryDialogs}
        title={
          t('product-category.edit.title', 'Edit product category') +
          ' - ' +
          categoryWithActiveDialog?.category.name
        }
      >
        {categoryWithActiveDialog?.category && (
          <EditProductCategoryForm
            productCategory={categoryWithActiveDialog.category}
            onSubmit={async (productCategory: IProductCategoryUpdateInput) => {
              try {
                await onUpdateProductCategory(
                  categoryWithActiveDialog.category.id,
                  productCategory,
                )
                closeCategoryDialogs()
              } catch (err) {
                /* empty */
              }
            }}
          />
        )}
      </Modal>
      <Modal
        isOpen={isCreateProductModalOpen}
        onClose={onCloseCreateProductModal}
        title={t('product.create.title', 'Create new product')}
      >
        <CreateProductForm
          categories={categories}
          onSubmit={async (product: IProductCreateRequest) => {
            try {
              await onCreateProduct(product)
              onCloseCreateProductModal()
            } catch (err) {
              /* empty */
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={productWithActiveDialog?.dialog === 'edit'}
        onClose={closeProductDialogs}
        title={
          t('product.edit.title', 'Edit product') +
          ' - ' +
          productWithActiveDialog?.product.name
        }
      >
        {productWithActiveDialog && (
          <EditProductForm
            product={productWithActiveDialog.product}
            categories={categories}
            onSubmit={async (product: IProductUpdateInput) => {
              try {
                await onUpdateProduct(
                  productWithActiveDialog.product.id,
                  product,
                )
                closeProductDialogs()
              } catch (err) {
                /* empty */
              }
            }}
          />
        )}
      </Modal>
      <ActionMenu
        isOpen={productWithActiveDialog?.dialog === 'menu'}
        onClose={closeProductDialogs}
        title={productWithActiveDialog?.product.name || ''}
        items={
          !productWithActiveDialog?.product
            ? []
            : [
                {
                  title: productWithActiveDialog.product.isActive
                    ? t(
                        'product.availability.deactivate.action',
                        'Remove from sale',
                      )
                    : t(
                        'product.availability.activate.action',
                        'Make available for sale',
                      ),
                  explainer: productWithActiveDialog.product.isActive ? (
                    <Trans
                      i18nKey="product.availability.deactivate.explainer"
                      defaults="This product won\'t be visible in the page <page>take-orders</page> and can\'t be purchased"
                      components={{
                        page: <PageLink />,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="product.availability.activate.explainer"
                      defaults="This product will be visible in the page <page>take-orders</page>"
                      components={{
                        page: <PageLink />,
                      }}
                    />
                  ),
                  onClick: async () => {
                    try {
                      if (!productWithActiveDialog.product) {
                        return
                      }
                      await onUpdateProduct(
                        productWithActiveDialog.product.id,
                        {
                          isActive: !productWithActiveDialog.product.isActive,
                        },
                      )
                      closeProductDialogs()
                    } catch (err) {
                      /* empty */
                    }
                  },
                },
                {
                  title: t('common.actions.edit', 'Edit'),
                  explainer: t(
                    'product.edit.explainer',
                    "Modify this product's details, price, and settings",
                  ),
                  onClick: () =>
                    openProductToEdit(productWithActiveDialog.product.id),
                },
                'divider',
                {
                  title: t('common.actions.delete', 'Delete'),
                  explainer: t(
                    'product.delete.explainer',
                    'Remove this product from your catalog. Sales history will be preserved',
                  ),
                  isDestructive: true,
                  onClick: () =>
                    openProductToDelete(productWithActiveDialog.product.id),
                },
              ]
        }
      />
      <ActionMenu
        isOpen={categoryWithActiveDialog?.dialog === 'menu'}
        onClose={closeCategoryDialogs}
        title={categoryWithActiveDialog?.category.name || ''}
        items={
          !categoryWithActiveDialog?.category
            ? []
            : [
                {
                  title: t('common.actions.edit', 'Edit'),
                  explainer: t(
                    'product-category.edit.explainer',
                    "Modify this category's name and color",
                  ),
                  onClick: () =>
                    openProductCategoryToEdit(
                      categoryWithActiveDialog.category.id,
                    ),
                },
                'divider',
                {
                  title: t('common.actions.delete', 'Delete'),
                  explainer: t(
                    'product-category.delete.explainer',
                    'Remove this category from your catalog. Products within it will become uncategorized',
                  ),
                  isDestructive: true,
                  onClick: () =>
                    openProductCategoryToDelete(
                      categoryWithActiveDialog.category.id,
                    ),
                },
              ]
        }
      />

      <ProductsAndCategoriesLayout
        categoriesView={
          <Box overflowY="auto">
            <ProductCategoryListComposite>
              <GridItem>
                <CreateProductCategoryButton
                  onClick={onOpenCreateProductCategoryModal}
                />
              </GridItem>
              {categories.map(category => (
                <ProductCategoryCard
                  key={category.id}
                  category={category}
                  icon={<EditIcon />}
                  onClick={() => openCategoryMenu(category)}
                />
              ))}
            </ProductCategoryListComposite>
          </Box>
        }
        productsView={
          categories.length === 0 && products.length === 0 ? (
            <HintText>
              {t(
                'product.create.manage-products-page.empty-state-hint',
                'First, create a prduct category by clicking on "➕$t(product-category.create.action)"',
              )}
            </HintText>
          ) : (
            <Box overflowY="auto">
              <ProductListComposite>
                <GridItem display="grid">
                  <CreateProductButton onClick={onOpenCreateProductModal} />
                </GridItem>
                {productsSortedByCategoryWithColors.map(
                  ({ product, color }) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      icon={<EditIcon />}
                      color={color}
                      onClick={() => openProductMenu(product)}
                    />
                  ),
                )}
              </ProductListComposite>
            </Box>
          )
        }
      />
    </>
  )
}

ManageProductsView.Loading = ProductsAndCategoriesLayout.Loading

const Error: FC = () => {
  return (
    <Alert data-testid="ManageProductsView-Error" status="error">
      <AlertIcon />
      <AlertDescription>
        An error occured while getting the information for this page.
      </AlertDescription>
    </Alert>
  )
}

ManageProductsView.Error = Error

export default ManageProductsView
