import {
  IProductCreateRequest,
  IProductUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Product'
import {
  IProductCategoryCreateRequest,
  IProductCategoryUpdateInput,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useFetchCategories from '../../shared/requests/useFetchCategories'
import useFetchProducts from '../../shared/requests/useFetchProducts'
import sortProductsByName from '../../shared/utils/sortProductsByName'
import useCreateProduct from '../requests/useCreateProduct'
import useCreateProductCategory from '../requests/useCreateProductCategory'
import useDeleteProduct from '../requests/useDeleteProduct'
import useDeleteProductCateogry from '../requests/useDeleteProductCategory'
import useUpdateProduct from '../requests/useUpdateProduct'
import useUpdateProductCategory from '../requests/useUpdateProductCategory'
import ManageProductsView from '../views/ManageProductsView'

const ManageProductsPage: FC = () => {
  const { t } = useTranslation()

  const categoriesQuery = useFetchCategories()

  const productsQuery = useFetchProducts()

  const createProductCategory = useCreateProductCategory()
  const updateProductCategory = useUpdateProductCategory()

  const createProduct = useCreateProduct()
  const updateProduct = useUpdateProduct()
  const deleteProduct = useDeleteProduct()
  const deleteProductCategory = useDeleteProductCateogry()

  const onCreateProductCategory = async (
    productCategory: IProductCategoryCreateRequest,
  ) => {
    await createProductCategory.mutateAsync(productCategory)
  }

  const onUpdateProductCategory = async (
    id: string,
    productCategory: IProductCategoryUpdateInput,
  ) => {
    await updateProductCategory.mutateAsync({ id, productCategory })
  }

  const onCreateProduct = async (product: IProductCreateRequest) => {
    await createProduct.mutateAsync(product)
  }

  const onUpdateProduct = async (id: string, product: IProductUpdateInput) => {
    await updateProduct.mutateAsync({ id, product })
  }

  const onDeleteProduct = async (productId: string) => {
    await deleteProduct.mutateAsync(productId)
  }

  const onDeleteProductCategory = async (productCategoryId: string) => {
    await deleteProductCategory.mutateAsync(productCategoryId)
  }

  const sortedProducts = useMemo(() => {
    return sortProductsByName(productsQuery.data || [])
  }, [productsQuery.data])

  if (categoriesQuery.isError || productsQuery.isError) {
    return <ManageProductsView.Error />
  }

  if (categoriesQuery.isPending || productsQuery.isPending) {
    return <ManageProductsView.Loading />
  }

  // TODO: more elegant way to handle no data
  if (!categoriesQuery.data || !productsQuery.data) {
    return t('common.status.no-data', 'No data!')
  }

  return (
    <ManageProductsView
      categories={categoriesQuery.data}
      products={sortedProducts}
      onCreateProduct={onCreateProduct}
      onUpdateProductCategory={onUpdateProductCategory}
      onUpdateProduct={onUpdateProduct}
      onCreateProductCategory={onCreateProductCategory}
      onDeleteProduct={onDeleteProduct}
      onDeleteProductCategory={onDeleteProductCategory}
    />
  )
}

export default ManageProductsPage
