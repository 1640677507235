import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import useFetchCategories from '../../shared/requests/useFetchCategories'
import useFetchProducts from '../../shared/requests/useFetchProducts'
import getOrderTotal from '../../shared/utils/getOrderTotal'
import TakeOrdersLayout from '../layouts/TakeOrdersLayout'
import SelectOrderItemsPanel from '../views/SelectOrderItemsPanel'
import TakeOrdersSidebar from '../views/TakeOrdersSidebar'

import { PaymentStatus } from 'aupiq-pos-shared/src/schemas/Payment'
import { isToday } from 'date-fns'
import { assert } from 'tsafe'
import Modal from '../../shared/components/Modal'
import sortProductsByName from '../../shared/utils/sortProductsByName'
import MakePaymentPanel from '../views/MakePaymentPanel/MakePaymentPanel'
import useOrderManagement from './hooks/useOrderManagement'
import usePaymentProcessing from './hooks/usePaymentProcessing'

const TakeOrdersPage: FC = () => {
  const { t } = useTranslation()

  const categoriesQuery = useFetchCategories()
  const productsQuery = useFetchProducts()

  const {
    orders: allOrders,
    isPending: ordersQueryIsPending,
    selectedOrderDetails,
    clearOrderSelection,
    selectedOrder,
    selectOrder,
    udpateProductQuantity,
    selectOrderLineByProductId,
    cancelSelectedOrder,
    addProductToSelectedOrder,
    createAndSelectOrder,
  } = useOrderManagement()

  const {
    isPaymentPanelOpen,
    closePaymentPanel,
    openPaymentPanel,
    payAndSaveOrder,
  } = usePaymentProcessing()

  useEffect(() => {
    if (!selectedOrder) {
      closePaymentPanel()
    }
  }, [selectedOrder, closePaymentPanel])

  const todaysOrders = allOrders.filter(order => isToday(order.created_at))

  const isPending =
    ordersQueryIsPending || categoriesQuery.isPending || productsQuery.isPending

  const productsToShow = useMemo(() => {
    return sortProductsByName(
      productsQuery.data?.filter(product => product.isActive) || [],
    )
  }, [productsQuery.data])

  if (isPending) {
    return <TakeOrdersLayout.Loading />
  }

  const orderTotal = selectedOrder ? getOrderTotal(selectedOrder) : 0

  const paidAmount = selectedOrder?.paidAmount || 0

  const amountStillToPay = orderTotal - paidAmount

  // TODO: more elegant way to handle no data
  if (!categoriesQuery.data || !productsQuery.data) {
    return t('common.status.no-data', 'No data!')
  }

  return (
    <>
      <Modal
        size={{ base: 'xl', md: '2xl' }}
        isOpen={Boolean(selectedOrder && isPaymentPanelOpen)}
        title={t('payment.title', 'Payment')}
        onClose={closePaymentPanel}
      >
        {selectedOrder && (
          <MakePaymentPanel
            amountStillToPay={amountStillToPay}
            onPay={async ({ amount, method }) => {
              await payAndSaveOrder({
                id: uuid(),
                orderId: selectedOrder.id,
                amount,
                method,
                status: PaymentStatus.Complete,
              })
              if (amount >= amountStillToPay) {
                closePaymentPanel()
              }
            }}
          />
        )}
      </Modal>

      <TakeOrdersLayout
        sidebarView={
          <TakeOrdersSidebar
            orders={todaysOrders}
            onClickCreateOrder={() => {
              createAndSelectOrder()
            }}
            onClickOrder={selectOrder}
            onUpdateProductQuantity={udpateProductQuantity}
            selectedOrderDetails={selectedOrderDetails}
            onCancelOrder={cancelSelectedOrder}
            onOpenPaymentPanel={openPaymentPanel}
            isPaymentPanelOpen={isPaymentPanelOpen}
            onClickOrderLine={selectOrderLineByProductId}
            onCloseOrder={() => {
              clearOrderSelection()
            }}
          />
        }
        mainPanel={
          <SelectOrderItemsPanel
            categories={categoriesQuery.data}
            products={productsToShow}
            onSelectProduct={productId => {
              const product = productsToShow.find(
                product => product.id === productId,
              )

              assert(product)

              if (!selectedOrder) {
                createAndSelectOrder(product)
                return
              }

              addProductToSelectedOrder(product)
            }}
          />
        }
      />
    </>
  )
}

export default TakeOrdersPage
