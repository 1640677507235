import {
  IBusiness,
  IBusinessUpdateInput,
} from 'aupiq-pos-shared/src/schemas/Business'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessForm from './BusinessForm'

interface Props {
  business: IBusiness
  onSubmit: (business: IBusinessUpdateInput) => Promise<void>
}

const EditBusinessForm: FC<Props> = ({ business, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <BusinessForm
      initialValues={business}
      submitButtonText={t('common.actions.update', 'Update')}
      onSubmit={onSubmit}
    />
  )
}

export default EditBusinessForm
