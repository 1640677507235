import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  IProductCategory,
  IProductCategoryCreateRequest,
} from 'aupiq-pos-shared/src/schemas/ProductCategory'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import useConnectedUser from '../../shared/requests/useConnectedUser'

const useCreateProductCategory = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: user } = useConnectedUser()

  return useMutation<IProductCategory, Error, IProductCategoryCreateRequest>({
    mutationFn: async productCategoryInput => {
      return axios.post(
        `/api/businesses/${user?.businessId}/categories`,
        productCategoryInput,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'categories'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t(
          'product-category.errors.creation',
          'while creating the product category',
        ),
      })
    },
  })
}

export default useCreateProductCategory
