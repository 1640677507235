import { Text } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppRoute, { routesConfig } from '../../app/AppRoute'

interface Props {
  children?: string[]
}

const PageLink: FC<Props> = ({ children }) => {
  const { t } = useTranslation()
  const pageName = children?.[0] as AppRoute
  if (!pageName || !routesConfig[pageName]) {
    return
  }
  return (
    <Link to={'/' + routesConfig[pageName].id}>
      <Text as="span" color="blue.500" decoration="underline">
        {routesConfig[pageName].icon}{' '}
        {t(`pages.${routesConfig[pageName].id}`, 'fff')}
      </Text>
    </Link>
  )
}

export default PageLink
