import {
  IOrder,
  IOrderCreateRequest,
  OrderCancelingReason,
  OrderStatus,
} from 'aupiq-pos-shared/src/schemas/Order'
import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { useState } from 'react'

import { assert } from 'tsafe'
import useCreateOrder from '../../requests/useCreateOrder'
import useFetchOrders from '../../requests/useFetchOrders'
import useUpdateOrder from '../../requests/useUpdateOrder'
import addProductToOrder from '../../utils/addProductToOrder'
import useOrderValidation from './useOrderValidation'

interface SelectedOrderDetails {
  orderId: string
  orderLineProductId?: string
}

const updateQuantityInOrderLines = (
  orderLines: IOrder['orderLines'],
  orderLineId: string,
  quantity: number,
) => {
  // if quantity <= 0, remove orderLine
  if (quantity <= 0) {
    return orderLines.filter(orderLine => orderLine.product.id !== orderLineId)
  }

  return orderLines.map(orderLine => {
    if (orderLine.product.id !== orderLineId) {
      return orderLine
    }
    return {
      ...orderLine,
      quantity,
    }
  })
}

const useOrderManagement = () => {
  // State declarations
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<
    SelectedOrderDetails | undefined
  >(undefined)

  // External hooks and queries
  const { validateOrderUpdate } = useOrderValidation()
  const ordersQuery = useFetchOrders()
  const createOrder = useCreateOrder()
  const updateOrder = useUpdateOrder()

  // Computed states

  const orders: IOrder[] = ordersQuery.data || []

  const selectedOrder: IOrder | undefined =
    selectedOrderDetails &&
    orders.find(order => order.id === selectedOrderDetails.orderId)

  // Utility functions

  const createAndSelectOrder = async (initialProduct?: IProduct) => {
    const newOrderRequest: IOrderCreateRequest = {
      status: OrderStatus.Ongoing,
      orderLines: initialProduct
        ? [
            {
              product: initialProduct,
              quantity: 1,
            },
          ]
        : [],
    }

    const newOrder = await createOrder.mutateAsync(newOrderRequest)

    setSelectedOrderDetails({
      orderId: newOrder.id,
    })
  }

  const clearOrderSelection = () => {
    setSelectedOrderDetails(undefined)
  }

  const selectOrder = (orderId: string) => {
    setSelectedOrderDetails({
      orderId,
    })
  }

  const selectOrderLineByProductId = (productId: string) => {
    setSelectedOrderDetails(selectionDetails => {
      if (selectionDetails?.orderId === undefined) {
        return
      }
      return {
        orderId: selectionDetails.orderId,
        orderLineProductId: productId,
      }
    })
  }

  const updateSelectedOrder = async (
    updateFn: (selectedOrder: IOrder) => IOrder,
  ) => {
    if (!selectedOrder) {
      return
    }

    const newSelectedOrder = updateFn(selectedOrder)

    await updateOrder.mutateAsync({
      id: selectedOrder.id,
      order: newSelectedOrder,
    })
  }

  const addProductToSelectedOrder = async (product: IProduct) => {
    assert(selectedOrder)

    if (!validateOrderUpdate(selectedOrder)) {
      return
    }
    await updateSelectedOrder(selectedOrder =>
      addProductToOrder(selectedOrder, product),
    )
    selectOrderLineByProductId(product.id)
  }

  const udpateProductQuantity = async (quantity: number) => {
    const selectedOrderLineId = selectedOrderDetails?.orderLineProductId
    if (!selectedOrderLineId) {
      return
    }

    await updateSelectedOrder(order => ({
      ...order,
      orderLines: updateQuantityInOrderLines(
        order.orderLines,
        selectedOrderLineId,
        quantity,
      ),
    }))
  }

  const cancelSelectedOrder = async (cancelingReason: OrderCancelingReason) => {
    await updateSelectedOrder(order => ({
      ...order,
      canceledAt: new Date(),
      status: OrderStatus.Canceled,
      cancelingReason: cancelingReason,
    }))

    clearOrderSelection()
  }

  return {
    isPending: ordersQuery.isPending,
    orders,
    selectedOrderDetails,
    selectOrder,
    udpateProductQuantity,
    createAndSelectOrder,
    clearOrderSelection,
    selectedOrder,
    selectOrderLineByProductId,
    cancelSelectedOrder,
    updateSelectedOrder,
    addProductToSelectedOrder,
  }
}

export default useOrderManagement
