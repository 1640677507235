import { Box, Grid, GridItem, Heading, useDisclosure } from '@chakra-ui/react'
import { compareAsc } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CreateUserButton from '../../manage-users/components/CreateUserButton'
import CreateUserForm from '../../manage-users/components/CreateUserForm'
import useCreateUserForBusiness from '../../manage-users/requests/useCreateUserForBusiness'
import useFetchBusinessById from '../../settings/requests/useFetchBusinessById'
import Modal from '../../shared/components/Modal'
import UserCard from '../../shared/components/UserCard'
import useFetchUsersForBusiness from '../requests/useFetchUsersForBusiness'

const ManageBusinessUsersPage: FC = () => {
  const { t } = useTranslation()
  const { businessId } = useParams<{ businessId: string }>()

  const { data: users, isLoading } = useFetchUsersForBusiness(businessId)
  const { data: business } = useFetchBusinessById(businessId)

  const createUserForBusiness = useCreateUserForBusiness(businessId)

  const {
    isOpen: isCreateUserModalOpen,
    onOpen: onOpenCreateUserModal,
    onClose: onCloseCreateUserModal,
  } = useDisclosure()

  if (isLoading) {
    return <div>loading...</div>
  }

  return (
    <>
      <Modal
        title={t('user.create.title', 'Create new user')}
        isOpen={isCreateUserModalOpen}
        onClose={onCloseCreateUserModal}
      >
        <CreateUserForm
          onSubmit={async user => {
            await createUserForBusiness.mutateAsync(user)
            onCloseCreateUserModal()
          }}
        />
      </Modal>

      <Grid overflowY="hidden" templateRows="auto 1fr">
        <Heading pb={4}>Users for business {business?.name}</Heading>
        <Box p={4} overflowY="auto">
          <Grid templateColumns="repeat(auto-fill, minmax(240px, 1fr))" gap={4}>
            <CreateUserButton onClick={onOpenCreateUserModal} />
            {users
              ?.toSorted((a, b) => compareAsc(a.created_at, b.created_at))
              .map(user => {
                return (
                  <GridItem key={user.id}>
                    <UserCard user={user} />
                  </GridItem>
                )
              })}
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default ManageBusinessUsersPage
