import { IProduct } from 'aupiq-pos-shared/src/schemas/Product'
import { IProductCategory } from 'aupiq-pos-shared/src/schemas/ProductCategory'
import { FC, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import HintText from '../../../shared/components/text/HintText'
import ProductsAndCategoriesLayout from '../../../shared/layouts/ProductsAndCategoriesLayout'
import getProductsSortedByCategoryWithColors from '../../../shared/utils/getProductsSortedByCategoryWithColors'

import { Box } from '@chakra-ui/react'
import PageLink from '../../../shared/components/PageLink'
import ProductCategoryList from './components/ProductCategoryList'
import ProductList from './components/ProductList'

interface Props {
  products: IProduct[]
  categories: IProductCategory[]
  onSelectProduct: (productId: IProduct['id']) => void
}

const SelectOrderItemsPanel: FC<Props> & { Loading: FC } = ({
  products,
  categories,
  onSelectProduct,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined)

  const onClickCategory = (categoryId: string) => {
    if (selectedCategoryId === categoryId) {
      return setSelectedCategoryId(undefined)
    }
    setSelectedCategoryId(categoryId)
  }

  const productsSortedByCategoryWithColors = useMemo(() => {
    return getProductsSortedByCategoryWithColors(products, categories)
  }, [products, categories])

  const filteredProductsWithColors = useMemo(() => {
    if (selectedCategoryId === undefined) {
      return productsSortedByCategoryWithColors
    }
    return productsSortedByCategoryWithColors.filter(
      ({ product }) => product.categoryId === selectedCategoryId,
    )
  }, [selectedCategoryId, productsSortedByCategoryWithColors])

  return (
    <ProductsAndCategoriesLayout
      categoriesView={
        <Box overflowY="auto">
          <ProductCategoryList
            categories={categories}
            selectedCategoryId={selectedCategoryId}
            onClickCategory={onClickCategory}
            onClearSelection={() => {
              setSelectedCategoryId(undefined)
            }}
          />
        </Box>
      }
      productsView={
        products.length > 0 ? (
          <Box overflowY="auto">
            <ProductList
              productsWithColors={filteredProductsWithColors}
              onClickProduct={onSelectProduct}
            />
          </Box>
        ) : (
          <HintText>
            <Trans
              i18nKey="product.create.take-orders-page.empty-state-hint"
              defaults="Click on <page>manage-products</page> to create products and categories"
              components={{
                page: <PageLink />,
              }}
            />
          </HintText>
        )
      }
    />
  )
}

SelectOrderItemsPanel.Loading = ProductsAndCategoriesLayout.Loading

SelectOrderItemsPanel.Loading = ProductsAndCategoriesLayout.Loading

export default SelectOrderItemsPanel
