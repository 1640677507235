import { useQuery } from '@tanstack/react-query'
import { businessSchema } from 'aupiq-pos-shared/src/schemas/Business'
import axios from 'axios'
import { z } from 'zod'

const useFetchBusinesses = () => {
  return useQuery({
    queryKey: ['businesses'],
    queryFn: () =>
      axios
        .get('/api/businesses')
        .then(res => z.array(businessSchema).parse(res.data)),
  })
}

export default useFetchBusinesses
