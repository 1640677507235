import { useToast } from '@chakra-ui/react'
import { IOrder, OrderStatus } from 'aupiq-pos-shared/src/schemas/Order'
import { useTranslation } from 'react-i18next'

const useOrderValidation = () => {
  const { t } = useTranslation()
  const toast = useToast()

  const isOrderEditable = (order: IOrder): boolean => {
    return (
      order.status !== OrderStatus.Paid && order.status !== OrderStatus.Canceled
    )
  }

  const validateOrderUpdate = (order: IOrder) => {
    if (!isOrderEditable(order)) {
      toast({
        status: 'warning',
        title: t('order.edit-locked.warning.title', 'Order cannot be modified'),
        description: t(
          'order.edit-locked.warning.explainer',
          'This order is $t(order.status.{{status}}) and cannot be modified. Please create a new order',
          {
            status: order.status,
          },
        ),
      })
      return false
    }

    return true
  }

  return {
    validateOrderUpdate,
  }
}

export default useOrderValidation
