import { Box, BoxProps } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props extends BoxProps {
  children: ReactNode
  theme?: 'default' | 'subtle' | 'danger'
}

const defaultTheme: Partial<BoxProps> = {
  bg: 'gray.100',
  _hover: {
    bg: 'gray.200',
  },
}

const subtleTheme: Partial<BoxProps> = {
  borderColor: 'gray.100',
  borderWidth: 2,
  _hover: {
    bg: 'gray.100',
    borderColor: 'gray.200',
  },
}

const dangerTheme: Partial<BoxProps> = {
  ...defaultTheme,
  color: 'red.500',
}

const DivButton: FC<Props> = ({
  children,
  theme = 'default',
  onClick,
  ...boxProps
}) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick?.(event as any)
    }
  }

  return (
    <Box
      display="grid"
      role="button"
      tabIndex={0}
      p={2}
      cursor="pointer"
      borderRadius="md"
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...(theme === 'danger'
        ? dangerTheme
        : theme === 'subtle'
          ? subtleTheme
          : defaultTheme)}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default DivButton
