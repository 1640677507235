import { useToast } from '@chakra-ui/react'
import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IPayment,
  IPaymentCreateRequest,
} from 'aupiq-pos-shared/src/schemas/Payment'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import useConnectedUser from '../../shared/requests/useConnectedUser'

const usePayOrder = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { data: user } = useConnectedUser()

  return useMutation<IPayment, DefaultError, IPaymentCreateRequest>({
    mutationFn: async (paymentDetails: IPaymentCreateRequest) => {
      return axios
        .post(
          `/api/businesses/${user?.businessId}/payments/pay`,
          paymentDetails,
        )
        .then(res => res.data)
    },
    onSuccess: (_, { orderId }) => {
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'paymentsByOrder', orderId],
      })
      queryClient.invalidateQueries({
        queryKey: ['business', user?.businessId, 'orders'],
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common.status.error', 'An error occured'),
        description: t('order.errors.saving', 'while saving the payments'),
      })
    },
  })
}

export default usePayOrder
