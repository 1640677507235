import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Grid, IconButton, Input } from '@chakra-ui/react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Digit from '../../../../shared/types/Digit/Digit'
import digitsToNumber from '../../../../shared/types/Digit/digitsToNumber'
import inputDigit from '../../../../shared/types/Digit/inputDigit'
import numberToDigits from '../../../../shared/types/Digit/numberToDigits'
import removeLastDigit from '../../../../shared/types/Digit/removeLastDigit'
import Numpad from '../../../components/Numpad'

interface Props {
  initialQuantity: number
  onConfirm: (quantity: number) => void
  onCancel: () => void
}

const EditProductQuantityForm: FC<Props> = ({
  initialQuantity,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation()

  const [quantityDigits, setQuantityDigits] = useState<Digit[]>(
    numberToDigits(initialQuantity),
  )

  const quantity = useMemo(() => {
    return digitsToNumber(quantityDigits)
  }, [quantityDigits])

  const incQuantity = () => {
    setQuantityDigits(numberToDigits(quantity + 1))
  }

  const decQuantity = () => {
    setQuantityDigits(numberToDigits(quantity - 1))
  }

  return (
    <Flex direction="column" gap={4}>
      <Grid templateColumns="1fr 2fr 1fr" w="3xs" gap={2} margin="auto">
        <IconButton
          aria-label="Decrement quantity"
          icon={<MinusIcon />}
          onClick={decQuantity}
        />
        <Input
          readOnly
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          value={quantity}
        />
        <IconButton
          aria-label="Increment quantity"
          icon={<AddIcon />}
          onClick={incQuantity}
        />
      </Grid>

      <Box p={4}>
        <Numpad
          onBackspace={() => setQuantityDigits(removeLastDigit)}
          onClear={() => setQuantityDigits([])}
          onInputDigit={newDigit =>
            setQuantityDigits(digits => {
              return inputDigit(newDigit, digits)
            })
          }
        />
      </Box>
      <Flex p={2} gap={2} justifyContent="end">
        <Button onClick={onCancel}>{t('common.actions.back', 'Back')}</Button>
        <Button
          colorScheme={quantity > 0 ? 'blue' : 'red'}
          onClick={() => onConfirm(quantity)}
        >
          {quantity > 0
            ? t('common.actions.save', 'Save')
            : t('common.actions.delete', 'Delete')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default EditProductQuantityForm
